import React, {useEffect, useMemo, useState} from 'react'
import {Box, Button, Typography, useTheme}   from "@mui/material"
import {fmtMinSec}                         from "../../util/prepkit-utils"
import LeftRight                    from "../LeftRight"

const millisecondsIn24Hours = 24 * 60 * 60 * 1000
const millisecondsPerHour = 60 * 60 * 1000

const ExpiringPrepKit = ({prepKit, onBuy, onPrepKitExpired}) => {

	const theme = useTheme()
	const [expired, setExpired] = useState(false)
	const [showCountdown, setShowCountdown] = useState(false)
	const [isUrgent, setIsUrgent] = useState(false)
	const [countdown, setCountdown] = useState(null)
	const [checkExpired, setCheckExpired] = useState(null)

	const expiresIn = useMemo(()=>{
		return prepKit && (new Date(prepKit.expires_at).getTime() - (new Date().getTime()))
	}, [prepKit, checkExpired])

	useEffect(() => {
		const interval = setInterval(() => {
			setCheckExpired(new Date().getTime())
		}, 1000) // check every second

		return () => {
			clearInterval(interval)
		}
	}, [])

	useEffect(() => {
		if (prepKit) {
			if (expiresIn < 0) {
				// expired
				setExpired(true)
				setShowCountdown(false)
				setCountdown(null)
				setIsUrgent(true)
			}
			else if (expiresIn < millisecondsIn24Hours) {
				// show countdown
				setExpired(false)
				setShowCountdown(true)
				setCountdown(fmtMinSec(expiresIn))
				setIsUrgent(expiresIn < millisecondsPerHour)
			}
			else {
				setExpired(false)
				setShowCountdown(false)
				setCountdown((expiresIn))
				setIsUrgent(false)
			}
		}
		else {
			setExpired(false)
			setShowCountdown(false)
		}
	}, [prepKit, expiresIn])

	useEffect(() => {
		if (prepKit && expired) {
			onPrepKitExpired(prepKit)
		}
	}, [prepKit, expired])

	let comp = null

	if (showCountdown) {
		comp = <LeftRight
			left={
				<Typography pl={2}
				            variant={"body2"}
				            sx={{
								fontWeight: 500,
								color: isUrgent ? theme.palette.error.main : 'inherit'
							}}
				>
					PrepKit {expiresIn === 0 ? `expired.` : `expires in ${countdown}`}
				</Typography>
			}
			right={
				<Button
					onClick={onBuy}
					fullWidth={true}
					color={"error"}
					sx={{
						textTransform: "none",
						textAlign: "left"
					}}
				>
					Buy
				</Button>
			}
		/>
	}

	return comp &&
		<Box position={"fixed"}
		     top={0}
		     left={"50%"}
		     width={400}
		     backgroundColor="background.paper"
		     boxShadow={2}
		     textAlign="center"
		     zIndex={1300}
		     sx={{
			     transform: 'translateX(-50%)',
			     borderBottomRightRadius: 5,
			     borderBottomLeftRadius: 5,
		     }}
		>
			{comp}
		</Box>
}

export default ExpiringPrepKit
