import {doGet} from "./do-fetch"

const fetchLLMModels = async () => {
	const response = await doGet(`/admin/models/llm`)
	const data = await response.json()
	return data
}

const fetchTTSModels = async () => {
	const response = await doGet(`/admin/models/tts`)
	const data = await response.json()
	return data
}

const fetchVoices = async () => {
	const response = await doGet(`/admin/voices`)
	const data = await response.json()
	return data
}

const fetchCategories = async () => {
	const response = await doGet(`/admin/categories`)
	const data = await response.json()
	return data
}

const fetchSignedMediaUrl = async (apiMediaUrl) => {
	const response = await doGet(apiMediaUrl)
	return await response.text()
}

export {fetchTTSModels, fetchLLMModels, fetchVoices, fetchSignedMediaUrl, fetchCategories}