import React from 'react'
import { Switch, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'

const lightSvg = encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 24 24" stroke="black"><path d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5M2 13h2c.55 0 1-.45 1-1s-.45-1-1-1H2c-.55 0-1 .45-1 1s.45 1 1 1m18 0h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1M11 2v2c0 .55.45 1 1 1s1-.45 1-1V2c0-.55-.45-1-1-1s-1 .45-1 1m0 18v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1M5.99 4.58c-.39-.39-1.03-.39-1.41 0-.39.39-.39 1.03 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0s.39-1.03 0-1.41zm12.37 12.37c-.39-.39-1.03-.39-1.41 0-.39.39-.39 1.03 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0 .39-.39.39-1.03 0-1.41zm1.06-10.96c.39-.39.39-1.03 0-1.41-.39-.39-1.03-.39-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0zM7.05 18.36c.39-.39.39-1.03 0-1.41-.39-.39-1.03-.39-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0z"></path></svg>`)
const darkSvg =  encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke="white"><path d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9c0-.46-.04-.92-.1-1.36-.98 1.37-2.58 2.26-4.4 2.26-2.98 0-5.4-2.42-5.4-5.4 0-1.81.89-3.42 2.26-4.4-.44-.06-.9-.1-1.36-.1"></path></svg>`)

const CustomSwitch = styled(Switch)(({ theme }) => ({
	'& .MuiSwitch-switchBase': {
		'&.Mui-checked': {
			color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.secondary.main,
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.secondary.main,
			},
		},
	},
	'& .MuiSwitch-thumb': {
		backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'&::before': {
			content: '""',
			width: 16,
			height: 16,
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			backgroundImage: `url(${theme.palette.mode === 'light' ? 'data:image/svg+xml;utf8,' + lightSvg  : 'data:image/svg+xml;utf8,' + darkSvg})}`,
		},
	},
	'& .MuiSwitch-track': {
		backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.secondary.main,
	},
}))

function ThemeToggle({ toggleTheme }) {
	const theme = useTheme()
	const themeType = theme.palette.mode

	const handleThemeToggle = () => {
		toggleTheme(themeType === 'light' ? 'dark' : 'light')
	}

	return (
		<CustomSwitch
			checked={themeType === 'dark'}
			onChange={handleThemeToggle}
		/>
	)
}

export default ThemeToggle
