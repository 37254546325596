import React, { useEffect, useRef, useState } from 'react'
import {
	TextField,
	Button,
	Link,
	Container,
	Box,
	Typography,
	useTheme,
	InputAdornment,
	IconButton
} from '@mui/material'
import {
	ArrowBack,
	Check,
	Visibility,
	VisibilityOff
} from '@mui/icons-material'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { getSupabase } from "../../util/supabase-utils"
import { useLocation } from "react-router-dom"

const supabase = getSupabase()

const Login = ({ switchView }) => {
	const theme = useTheme()
	const location = useLocation()

	// State variables
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [showPassword, setShowPassword] = useState(false)
	const [error, setError] = useState('')
	const [captchaToken, setCaptchaToken] = useState(null)
	const [isSubmitting, setIsSubmitting] = useState(false)

	// Ref for HCaptcha
	const captchaRef = useRef(null)

	// HCaptcha site key
	const captchaSiteKey = process.env.REACT_APP_HCAPTCHA_SITE_KEY

	// Handle login attempt
	const handleLogin = () => {
		setError('')
		setIsSubmitting(true)
		if (captchaRef.current) {
			captchaRef.current.execute()
		} else {
			setError('CAPTCHA is not ready. Please try again.')
			setIsSubmitting(false)
		}
	}

	// Handle CAPTCHA verification
	const handleVerify = async (token) => {
		setCaptchaToken(token)
		try {
			const options = { captchaToken: token, redirectTo: window.location.href }
			const { error } = await supabase.auth.signInWithPassword({ email, password, options })
			if (error) {
				setError(error.message)
				if (captchaRef.current) {
					captchaRef.current.resetCaptcha()
				}
			}
		} catch (err) {
			setError('An unexpected error occurred. Please try again.')
			console.error(err)
		} finally {
			setIsSubmitting(false)
		}
	}

	// Handle CAPTCHA load (optional)
	const handleLoadCaptcha = () => {
		// Optional: Actions to perform when CAPTCHA is loaded
	}

	// Handle CAPTCHA error
	const handleErrorCaptcha = (err) => {
		setError('CAPTCHA failed to load. Please refresh the page.')
		setIsSubmitting(false)
		console.error(err)
	}

	return (
		<Container maxWidth="sm">
			<Box component="form" mt={4}>
				{/* Back Button */}
				<Typography
					variant="h6"
					sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', mb: 2 }}
					onClick={() => switchView('')}
				>
					<ArrowBack sx={{ mr: 0.5 }} /> Login
				</Typography>

				{/* Email Field */}
				<TextField
					label="Email"
					type="email"
					fullWidth
					margin="normal"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					onKeyDown={(e) => e.key === "Enter" && handleLogin()}
					required
				/>

				{/* Password Field */}
				<TextField
					label="Password"
					type={showPassword ? 'text' : 'password'}
					fullWidth
					margin="normal"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					onKeyDown={(e) => e.key === "Enter" && handleLogin()}
					required
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={() => setShowPassword(!showPassword)}
									onMouseDown={(event) => event.preventDefault()}
									edge="end"
									aria-label={showPassword ? 'Hide password' : 'Show password'}
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>

				{/* HCaptcha Component */}
				<HCaptcha
					ref={captchaRef}
					size="invisible"
					sitekey={captchaSiteKey}
					onLoad={handleLoadCaptcha}
					onVerify={handleVerify}
					onError={handleErrorCaptcha}
					theme={theme.palette.mode}
				/>

				{/* Error Message */}
				{error && (
					<Typography color="error" sx={{ mt: 1 }}>
						{error}
					</Typography>
				)}

				{/* Submit Button */}
				<Button
					variant="outlined"
					size="large"
					fullWidth
					sx={{ mt: 2, mb: 2, py: 1.5 }}
					onClick={handleLogin}
					disabled={isSubmitting}
				>
					{isSubmitting ? 'Logging in...' : 'Continue'}
				</Button>

				{/* Forgot Password Link */}
				<Link
					sx={{ cursor: 'pointer', display: 'block', textAlign: 'right', mt: 1 }}
					onClick={() => switchView('forgotPassword')}
				>
					Forgot Password?
				</Link>

				{/* CAPTCHA Verification Indicator */}
				{captchaToken && (
					<Box display="flex" justifyContent="center" alignItems="center" mt={2}>
						<Check color="success" fontSize="small" />
						<Typography variant="body2" ml={1}>
							Verified Human
						</Typography>
					</Box>
				)}
			</Box>
		</Container>
	)
}

export default Login
