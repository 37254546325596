
const isBlankOrEmpty = (s) => {
	return !s || (typeof s === 'string' && s.trim() === '')
}

const isIntegerBetween = (n, l, h) => {
	try {
		const i = parseInt(n)
		return i >= l && i <= h
	}
	catch(e) {
		return false
	}
}

const rndTo3Dec = (dec) => {
	return Math.round(dec * 1000) / 1000
}

const _formattedCurrency = new Intl.NumberFormat(undefined, { // 'undefined' defaults to the browser's locale
	style: 'currency',
	currency: 'USD',
})

const currency = (v, d) => {
	return _formattedCurrency.format(v || d);
}

const _formattedMicroCurrency = new Intl.NumberFormat(undefined, { // 'undefined' defaults to the browser's locale
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 4,
	maximumFractionDigits: 4,
})

const microCurrency = (v, d) => {
	return _formattedMicroCurrency.format(v || d);
}

function getColorForScore(value) {
	// Define color stops
	const colorStops = [
		{ value: 0.5, color: '#FF5722' },
		{ value: 0.6, color: '#FF9800' },
		{ value: 0.7, color: '#FFC107' },
		{ value: 0.8, color: '#FFEB3B' },
		{ value: 0.9, color: '#CDDC39' },
		{ value: 1.0, color: '#4CAF50' }
	];

	// Ensure value is within range
	value = Math.max(0.5, Math.min(1.0, value));

	// Find the two color stops to interpolate between
	let lowerStop = colorStops[0];
	let upperStop = colorStops[colorStops.length - 1];

	for (let i = 0; i < colorStops.length - 1; i++) {
		if (value >= colorStops[i].value && value <= colorStops[i + 1].value) {
			lowerStop = colorStops[i];
			upperStop = colorStops[i + 1];
			break;
		}
	}

	// Calculate the interpolation factor
	const range = upperStop.value - lowerStop.value;
	const factor = (value - lowerStop.value) / range;

	// Interpolate between the two colors
	const r = Math.round(parseInt(lowerStop.color.slice(1, 3), 16) * (1 - factor) + parseInt(upperStop.color.slice(1, 3), 16) * factor);
	const g = Math.round(parseInt(lowerStop.color.slice(3, 5), 16) * (1 - factor) + parseInt(upperStop.color.slice(3, 5), 16) * factor);
	const b = Math.round(parseInt(lowerStop.color.slice(5, 7), 16) * (1 - factor) + parseInt(upperStop.color.slice(5, 7), 16) * factor);

	// Convert back to hex
	return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

/**
 * Determines whether black or white text would be more readable on a given background color.
 *
 * @param {string} hexColor - The background color in hexadecimal format (e.g., "#ffffff" or "#fff").
 * @returns {string} - Returns "black" or "white" based on the background color's brightness.
 */
function getContrastingTextColor(hexColor) {
	// Remove the hash symbol if present
	let color = hexColor.replace('#', '');

	// Convert shorthand hex color (#fff) to full form (#ffffff)
	if (color.length === 3) {
		color = color.split('').map(char => char + char).join('');
	}

	// Parse the r, g, b values
	const r = parseInt(color.substr(0, 2), 16);
	const g = parseInt(color.substr(2, 2), 16);
	const b = parseInt(color.substr(4, 2), 16);

	// Calculate the brightness
	const brightness = (299 * r + 587 * g + 114 * b) / 1000;

	// Return black for bright colors, white for dark colors
	return brightness >= 200 ? 'black' : 'white';
}

const convertToRgba = (color, opacity) => {
	let rgba = null

	// Check if the color is in hex format
	if (color.startsWith('#')) {
		// Convert hex to rgb
		const hex = color.replace('#', '')
		const bigint = parseInt(hex, 16)
		const r = (bigint >> 16) & 255
		const g = (bigint >> 8) & 255
		const b = bigint & 255
		rgba = `rgba(${r},${g},${b},${opacity})`
	}

	// Check if the color is in rgb or rgba format
	else if (color.startsWith('rgb')) {
		// Extract the numeric values
		const values = color.match(/\d+/g)
		const r = values[0]
		const g = values[1]
		const b = values[2]
		rgba = `rgba(${r},${g},${b},${opacity})`
	}

	console.log({color, opacity, rgba})
	return rgba
}



export {convertToRgba, isBlankOrEmpty, isIntegerBetween, currency, microCurrency, rndTo3Dec, getContrastingTextColor, getColorForScore}
