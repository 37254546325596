import React, {useLayoutEffect, useRef, useState, useEffect} from 'react'
import Message                                               from "./Message"
import {logger}                                              from "../../../util/log-utils"
import {Button, Divider}                                     from "@mui/material"
import moment                                                from 'moment'
import {Bookmark, BookmarkOutlined}                          from "@mui/icons-material"
import {isAiMessage}                                         from "../../../util/prepkit-utils"

const log = logger("Messages", 1)

function Messages({ contextId, messages, bookmarks, currentMessageIndex, showMessagesAfter=0,
	                  onToggleBookmark, onMessageAnimationComplete, onMessageTypingComplete
}) {
	log.trace("Component render")
	const messageContainerRef = useRef(null)
	const [messageContainerRect, setMessageContainerRect] = useState(null)
	const [showOlderMessages, setShowOlderMessages] = useState(false)
	const [hasOlderMessages, setHasOlderMessages] = useState(false)
	const [filteredMessages, setFilteredMessages] = useState()
	const contextIdRef = useRef();

	/*
	 * This positions the messages container within the DOM, accounting for window resize events
	 * todo: is there a better way?
	 */
	useLayoutEffect(() => {

		filterMessages()

		const handleResize = () => {
			if (messageContainerRef.current) {

				const rect = messageContainerRef.current.getBoundingClientRect()

				if (!messageContainerRect ||
					messageContainerRect.left !== rect.left ||
					messageContainerRect.width !== rect.width) {
					// changed!
					setMessageContainerRect(rect)
				}
			}
		}
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])


	useEffect(() => {
		const prevContextId = contextIdRef.current;

		if (prevContextId !== contextId) {
			// contextId has changed
			setShowOlderMessages(false)
		}

		// Update the ref to the current id for the next render
		contextIdRef.current = contextId;
	}, [contextId])

	useEffect(() => {
		filterMessages()
	}, [messages, showOlderMessages])

	const filterMessages = () => {
		if(showMessagesAfter > 0) {
			const filteredMessages = messages.filter((m)=> moment(m.created_at).isAfter(moment(showMessagesAfter)))
			setHasOlderMessages(filteredMessages.length !== messages.length)

			if(showOlderMessages) {
				setFilteredMessages(messages)
			}
			else {
				setFilteredMessages(filteredMessages)
			}
		}
		else {
			setFilteredMessages(messages)
		}
	}

	const handleBookmark = (message) => {
		if(isAiMessage(message)) {
			onToggleBookmark(message)
		}
	}

	const handleDeleteBookmark = (message) => {
		alert("Delete Bookmark!")
	}

	return (
		<div ref={messageContainerRef} style={{fontSize: "20px"}}>
			{
				hasOlderMessages &&
				!showOlderMessages &&
					<div style={{textAlign: "center", paddingBottom: "50px"}}>
						<Button size="small"
						        style={{fontSize: "10px"}}
						        onClick={() => setShowOlderMessages(true)}
						>
							Show Older Messages
						</Button>
					</div>
			}

			{
				filteredMessages && filteredMessages.map((m, ix) => {
					return <Message key={`message-${m.id}-${m.message_type}-${m.message_sub_type}`}
					                message={m}
					                bookmarked={bookmarks && bookmarks.some(b => b.message_id === m.id)}
					                showBookmark={onToggleBookmark && isAiMessage(m)}
					                onToggleBookmark={handleBookmark}
					                onAnimationComplete={onMessageAnimationComplete}
					                onTypingComplete={onMessageTypingComplete}
					                isLastMessage={ix === filteredMessages.length-1}

					/>
				})
			}
		</div>
	)
}

export default React.memo(Messages)
