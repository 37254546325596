import {Box, CircularProgress, Container, Radio, TextField, Typography, useTheme} from "@mui/material"
import React, {useEffect, useState}                                               from "react"
import getSupabase                                                                from "../../util/supabase-utils"
import {useUserStore}                                                             from "../../state"
import {doGet}                                                                    from "../../util/do-fetch"

const UpdateUserProfile = ({switchView}) => {

	const theme = useTheme()
	const supabase = getSupabase()
	const {user} = useUserStore()
	const [userProfile, setUserProfile] = useState(null)
	const [preferredName, setPreferredName] = useState('')
	const [aiPersonalityId, setAiPersonalityId] = useState('')
	const [aiPersonalities, setAiPersonalities] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState('')

	useEffect(() => {
		if (user) {
			const fetchUserProfile = async () => {
				try {
					const response = await doGet(`/mini/user/profile`)
					const profile = await response.json()
					if (profile) {
						setUserProfile(profile)
						setPreferredName(profile.preferred_name || '')
						setAiPersonalityId(profile.ai_personality_id || '')
					}
					else {
						throw "No user profile exists."
					}
				}
				catch (err) {
					setUserProfile(null)
					setPreferredName('')
					setAiPersonalityId('')
				}
				finally {
					setLoading(false)
				}
			}

			const fetchAiPersonalities = async () => {
				try {
					const response = await doGet(`/mini/ais`)
					const data = await response.json()
					setAiPersonalities(data)
				}
				catch (err) {
					console.error(err)
				}
			}

			fetchUserProfile()
			fetchAiPersonalities()
		}
		else {
			setAiPersonalities(null)
			setUserProfile(null)
			setPreferredName('')
			setAiPersonalityId('')
		}
	}, [user])


	if (loading) {
		return (
			<Container>
				<Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
					<CircularProgress/>
				</Box>
			</Container>
		)
	}

	const handleCardClick = (id) => {
		setAiPersonalityId(id)
	}

	return (
		<Container>
			<Box>
				<Typography variant={"h5"}>What should I call you?</Typography>
				<TextField
					fullWidth
					label="Preferred Name"
					placeholder={"What should I call you?"}
					variant="outlined"
					value={preferredName}
					onChange={(e) => setPreferredName(e.target.value)}
					margin="normal"
				/>


				{/* AI Personalities Selection */}
				<Typography variant={"h5"} sx={{mt: 4}}>
					What what is your learning style?
				</Typography>
				{aiPersonalities &&
				aiPersonalities.map((personality) => (
					<Box
						key={`btn-${personality.id}`}
						onClick={() => handleCardClick(personality.id)}
						sx={{
							my: 1,
							cursor: 'pointer',
						}}
					>
						<Box display={"flex"}
						     flexDirection={"row"}
						     justifyContent={"flex-start"}
						     alignItems={"flex-start"}
						>
							<Radio
								value={personality.id}
								checked={aiPersonalityId === personality.id}
								sx={{
									'& .MuiSvgIcon-root': {
										fontSize: '22',
									},
								}}
							/>
							<Box>

								<Typography variant="h6" sx={{mt: 0.5}}>
									{personality.name}
								</Typography>
								<Typography variant="body">
									{personality.description}
								</Typography>
							</Box>

						</Box>
					</Box>
				))}
			</Box>
		</Container>
	)
}

export default UpdateUserProfile