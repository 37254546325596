import React, {useState}                                                     from 'react'
import BirdsyMiniLogo                                                                         from "../BirdsyMiniLogo"
import {Box, Chip, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, useTheme} from "@mui/material"
import {AccountCircleOutlined, AutoAwesome, Logout}                                           from "@mui/icons-material"
import AccountCircle                                                               from "@mui/icons-material/AccountCircle"
import ThemeToggle                                                           from "../ThemeToggle"
import {useUserStore}                                                        from "../../state"
import {useNavigate}                                                         from "react-router-dom"

const MiniNavbar = ({ toggleTheme }) => {

	const theme = useTheme()
	const navigate= useNavigate()
	const {user, signOut} = useUserStore()
	const [anchorEl, setAnchorEl] = useState(null)
	const [menuOpen, setMenuOpen] = useState(false)

	const logout = () => {
		setMenuOpen(false)
		signOut()
	}

	return (
		<nav style={{
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			padding: '5px',
			zIndex: 1000,
			backgroundColor: "transparent",
			xxbackdropFilter: "blur(10px)",
			xxWebkitBackdropFilter: "blur(10px)",
			border: "0px solid magenta"
		}}>
			<div style={{
				margin: "0px auto",
				padding: "5px 15px",
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
				backgroundColor: "transparent",
				border: "0px solid red"
			}}>
				<Box>
					<BirdsyMiniLogo style={{display: 'flex'}} width={130} height={45}/>
				</Box>
				{user &&
					<Box>
						{/*
						<Chip icon={<AutoAwesome style={{color: "gold"}} />}
			                 label="Upgrade to Pro"
			                 variant="outlined"
			                 sx={{mx:1, px: .5}}
						/>
						*/}
						<ThemeToggle toggleTheme={toggleTheme}/>
						<IconButton
							size="large"
							onClick={(event)=> {
								event.stopPropagation()
								event.preventDefault()
								setAnchorEl(event.currentTarget)
								setMenuOpen(true)
							}}
						>
							<AccountCircle/>
						</IconButton>

						<Menu
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={menuOpen}
							anchorEl={anchorEl}
							onClose={(event) => {
								event.stopPropagation()
								setAnchorEl(null)
								setMenuOpen(false)
							}}
						>
							<ListItemText
								secondary={user.email}
								sx={{px: 2}}
							/>
							{user &&
							<MenuItem onClick={() => navigate(`/account/profile`)}>
								<ListItemIcon>
									<AccountCircle fontSize="small"/>
								</ListItemIcon>
								<ListItemText primary="Profile"/>

							</MenuItem>
							}`
							{user &&
							<MenuItem onClick={logout}>
								<ListItemIcon>
									<Logout fontSize="small"/>
								</ListItemIcon>
								<ListItemText primary="Sign Out"/>
							</MenuItem>
							}
						</Menu>
					</Box>
				}
			</div>
		</nav>
	);
};

export default MiniNavbar;
