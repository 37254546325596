import React, {useEffect, useState} from 'react'
import {useParams}     from "react-router-dom"
import {doGet, doPost} from "../../util/do-fetch"
import {logger}        from "../../util/log-utils"
import ProductLayout           from "./ProductLayout"
import {Box, CircularProgress} from "@mui/material"
import EditProductModal        from "./EditProductModal"

const log = logger("Product", 1)

function Product() {
	const {productId} = useParams()
	const [product, setProduct] = useState(null)
	const [showEditProduct, setShowEditProduct] = useState(false)

	useEffect(() => {
		fetchProduct()
	}, [productId])

	const fetchProduct = async () => {
		if (!productId || productId.length === 0) {
			setProduct(null)
			return
		}

		try {
			const response = await doGet(`/admin/products/${productId}?include_costs=true`)
			const data = await response.json()
			setProduct(data)
		}
		catch (err) {
			log.error(err)
		}
	}

	const handleSaveProduct = async ({ name, shortName, token, category }) => {
		try {
			const options = {
				body: JSON.stringify({
					product: {
						name, shortName, token, categoryId: category
					}
				})
			}
			const response = await doPost(`/admin/products/${product.id}`, options)
			const data = await response.json()

			if (response.ok) {
				setProduct(data)
				setShowEditProduct(false)
			}
		}
		catch (err) {
			log.error(err)
		}
	}

	const handleEditProduct = () => {
		setShowEditProduct(true)
	}

	return product
		? <Box>
			<ProductLayout product={product} onEdit={handleEditProduct} />
			<EditProductModal product={product}
			                  onSave={handleSaveProduct}
			                  open={showEditProduct}
			                  onClose={() => setShowEditProduct(false)}
			/>
	      </Box>
		: <h2 style={{textAlign: "center"}}><CircularProgress size={18} /> Loading</h2>
}

export default Product
