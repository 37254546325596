import React                      from 'react'
import {Button, Container, Stack} from '@mui/material'
import {useNavigate}              from "react-router-dom"


function Admin() {

	const navigate = useNavigate()

	const buttons = ["Home", "Users", "Categories", "Products", "Packages", "Licenses", "Videos", "Topic Job Queue"]

	return (
		<Container maxWidth="xs">
			<Stack direction="column">
				{
					buttons.map((btn) => {
							return (<Button
								size="large"
								key={`btn-${btn}`}
								color="divider"
								sx={{m: 2, fontSize: '16px', p: 2}}
								variant="contained"
								onClick={() => navigate(`/admin/${btn === 'Home' ? '../' : btn.toLowerCase().replace(/\s+/g, '-')}`)}>{btn}
							</Button>)
						}
					)
				}
			</Stack>
		</Container>
	)
}

export default Admin