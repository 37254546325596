import './stylesheets/App.css'
import './stylesheets/Auth.css'
import {useCallback, useEffect, useMemo, useState}         from "react"
import {getSupabase}                                                     from "./util/supabase-utils"
import {createTheme, CssBaseline, GlobalStyles, ThemeProvider, useTheme} from '@mui/material'
import {createLight, createDark}                                         from "./util/theme-colors"
import {AdapterDayjs}                                      from "@mui/x-date-pickers/AdapterDayjs"
import {LocalizationProvider}                              from "@mui/x-date-pickers"
import AuthGate                                            from "./components/AuthGate"
import ThemeCssInjector                                    from "./components/ThemeCssInjector"
import {useUserStore}                                      from "./state"
import {logger}                                            from "./util/log-utils"
import {string}                                            from "prop-types"

const log = logger("App", 1)

function App() {
	log.trace("Component render")
	const supabase = getSupabase()
	const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
	const [themeName, setThemeName] = useState(prefersDarkMode ? 'dark' : 'light')
	const { session, setSession } = useUserStore()


	/* use this to see current user's info, roles, etc.
	const fn = async () => {
		const { data: { user } } = await supabase.auth.getUser()
		console.log(JSON.stringify({user}, null, 2))
	}
	fn()
	*/

	const appClass = useMemo(() => `App ${themeName}`, [themeName])

	useEffect(() => {
		if(!session) {
			log.debug("App.useEffect[]" + session)
			supabase.auth.getSession()
				.then(({data: {session}}) => {
					log.debug("after auth getsession: " + session)
					setSession(session)
				})
		}

		const { data: { subscription } } =
			supabase.auth.onAuthStateChange((_event, _session) => {
				log.debug({ e: "onAuthStateChange", event: _event, user: _session?.user })
				setSession(_session)
			})

		return () => subscription.unsubscribe()
	}, [])

	useEffect(() => {
		const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
		const handleChange = (e) => {
			setThemeName(e.matches ? 'dark' : 'light')
		}
		mediaQuery.addEventListener('change', handleChange);

		return () => mediaQuery.removeEventListener('change', handleChange);
	}, []);

	// ! solution is to use the createTheme method with actual themes we created in separate file
	const light = createLight()
	const dark = createDark()
	const lightTheme = useMemo(
		() =>
			createTheme({
				typography: {
					fontFamily: 'Poppins, Arial, sans-serif',
				},
				palette: {
					mode: "light",
					...light
				}
			}),
		[themeName]
	)
	const darkTheme = useMemo(
		() =>
			createTheme({
				typography: {
					fontFamily: 'Poppins, Arial, sans-serif',
				},
				palette: {
					mode: "dark",
					...dark
				}
			}),
		[themeName]
	)

	const toggleTheme = useCallback(() => {
		setThemeName((prevTheme) => {
			const newTheme = prevTheme === 'light' ? 'dark' : 'light'
			return newTheme
		})
	}, [themeName])

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
			<ThemeProvider theme={themeName === 'dark' ? darkTheme : lightTheme}>
				<GlobalStyles styles={{ html: { backgroundColor: themeName === 'dark' ? darkTheme.palette.background.paper + ' !important' : lightTheme.palette.background.paper + ' !important' } }} />
				<ThemeCssInjector/>
				<CssBaseline/>
				<div className={`${appClass} env-${process.env.NODE_ENV}`}>
					<AuthGate toggleTheme={toggleTheme}/>
				</div>
			</ThemeProvider>
		</LocalizationProvider>
	)
}

export default App
