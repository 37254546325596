import React, {useMemo, useState} from 'react'
import {useOutletContext}                                                             from 'react-router-dom'
import {
	Badge,
	Box,
	Button,
	Chip,
	CircularProgress,
	Divider,
	FormControlLabel,
	List,
	Switch,
	useTheme,
}                       from '@mui/material'
import Grid             from '@mui/material/Unstable_Grid2' // Grid version 2
import {doPost}         from "../../util/do-fetch"
import {logger}         from "../../util/log-utils"
import QuestionListItem from "./QuestionListItem"
import QuestionEditor   from "./QuestionEditor"
import TopicsTree       from "./TopicsTree"
import EditProductModal from "./EditProductModal"


const log = logger("ProductQuestions", 1)

function ProductQuestions({onUpdateProduct}) {
	const theme = useTheme()
	const {product} = useOutletContext()
	const [topic, setTopic] = useState(null)
	const [question, setQuestion] = useState(null)
	const [expandQuestions, setExpandQuestions] = useState(false)
	const [regenerating, setRegenerating] = useState(false)
	const [showEditProduct, setShowEditProduct] = useState(false)
	const [generatingQuestions, setGeneratingQuestions] = useState(Boolean(product.generate_questions_at))
	const [selectedQuestions, setSelectedQuestions] = useState({})

	const selectedQuestionsCount = useMemo(() => {
		return Object.values(selectedQuestions).filter(isSelected => isSelected).length
	}, [selectedQuestions])

	const renderTopicDetail = (t) => {
		if (!t) return null

		return <div>
			<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
				<div>
					<h3 style={{padding: 0, margin: 0}}>{t.name}</h3>
					<small>[{t.id}] {(t.questions && t.questions.length) || 0} questions, {t.target_question_count} targeted</small>
				</div>
				<div style={{textAlign: "right"}}>
					<FormControlLabel
						control={
							<Switch checked={expandQuestions}
							        onChange={() => setExpandQuestions(!expandQuestions)}
							/>
						}
						label="Expand"
					/>

					<Badge badgeContent={selectedQuestionsCount}
					       invisible={selectedQuestionsCount < 1}
					       color="error"
					       max={9999}
					>
						<Button variant="contained"
						        disabled={selectedQuestionsCount === 0 || regenerating}
						        onClick={async () => {
							        regenerateSelectedQuestions()
						        }}
						>
							{
								regenerating && <CircularProgress size={14} sx={{mr: 1}}/>
							}
							Regenerate
						</Button>
					</Badge>
				</div>
			</div>
			<Divider sx={{mt: 1}}/>
			<div>
				{
					renderQuestions(t.questions)
				}
			</div>
		</div>
	}

	const renderQuestions = (questions) => {
		if (!questions || questions.length === 0) return null
		return (
			<List>
				{questions.map((question, i) => (
					<QuestionListItem
						key={`qli-${question.id}`}
						question={question}
						index={i}
						onSelect={(isSelected) => handleSelectQuestion(question.id, isSelected)}
						selected={!!selectedQuestions[question.id]} // Check if the question is selected
						expanded={expandQuestions}
						onEdit={handleEditQuestion}
					/>
				))}
			</List>
		)
	}

	const getSelectedQuestionIds = () => {
		const questionIds = Object.keys(selectedQuestions).filter(id => selectedQuestions[id])
		return questionIds
	}

	const handleEditQuestion = (question) => {
		setQuestion(question)
	}

	const handleSelectQuestion = (questionId, isSelected) => {
		setSelectedQuestions(prevSelected => ({
			...prevSelected,
			[questionId]: isSelected
		}))
	}

	const handleCancelEditQuestion = () => {
		setQuestion(null)
	}

	const regenerateSelectedQuestions = async () => {
		setRegenerating(true)

		const options = {
			body: JSON.stringify({ questionIds: getSelectedQuestionIds()} )
		}
		const response = await doPost(`/admin/products/${product.id}/topic/${topic.id}/questions/regenerate`, options)
		if (response.ok) {
			const data = await response.json()
			const questions = data.questions

			// Update question in topic
			setTopic(prevTopic => {
				const updatedQuestionsList = prevTopic.questions.map(question => {
					// Find if the current question is in the selectedQuestions and replace it
					const updatedQuestion = questions.find(q => q.id === question.id)
					return updatedQuestion ? updatedQuestion : question
				})

				// Return the updated topic with the updated questions array
				const updatedTopic = {...prevTopic, questions: updatedQuestionsList}
				return updatedTopic
			})
			setSelectedQuestions({})
		}
		else {
			console.error('Failed to update question', await response.text())
		}

		setRegenerating(false)
	}

	const handleSaveQuestion = async (prevQuestion, updatedQuestion) => {
		const options = {
			body: JSON.stringify(updatedQuestion)
		}
		const response = await doPost(`/admin/products/${product.id}/topic/${topic.id}/question/${prevQuestion.id}`, options)
		if (response.ok) {
			const data = await response.json()
			const questionResponse = data.question

			// Update question in topic
			setTopic(prevTopic => {
				const updatedQuestions = prevTopic.questions.map(question => {
					// Replace the question by id with the updated question data
					if (question.id === prevQuestion.id) {
						const mergedQuestion = {...question, ...questionResponse} // Merge the old question data with the updated data
						return mergedQuestion
					}
					return question // Return the question as is if the IDs don't match
				})

				// Return the updated topic with the updated questions array
				const updatedTopic = {...prevTopic, questions: updatedQuestions}
				return updatedTopic
			})

			setQuestion(null)
		}
		else {
			// Handle the error case
			console.error('Failed to update question', await response.text())
		}
	}

	const handleToggleGenerateQuestions = async () => {
		try {
			const toggle = !generatingQuestions
			setGeneratingQuestions(toggle) // proactively set it to true, and revert if it fails

			const response = await doPost(`/admin/products/${product.id}/generate-questions?enable=${toggle}`)
			const data = await response.json()

			if (!data.success) {
				setGeneratingQuestions(!toggle) // set it back
				alert("Error enabling question generator.  Try refreshing the page.")
				throw "Error updating product"
			}
		}
		catch (err) {
			log.error(err)
		}
	}

	return <>
		<Grid xs={12} container>
			<Grid xs={4}>
				<Box style={{
					backgroundColor: theme.palette.background.paper,
					borderRadius: "5px",
					border: "1px solid " + theme.palette.divider,
					overflowY: "auto",
					maxHeight: "calc(100vh - 250px)",
					height: "100%"
				}}>
					<Box display="flex"
					     justifyContent="space-between"
					     alignItems="center"
					     sx={{borderBottom: '1px solid #ddd', p: 2, width: '100%'}}
					>
						<FormControlLabel
							control={
								<Switch checked={generatingQuestions}
								        onChange={handleToggleGenerateQuestions}
								/>
							}
							label="Enable Question Generator"
						/>

						<Chip label={product && product.llm_model}/>
					</Box>
					<TopicsTree topics={product && product.topics} selectedTopic={topic} onClick={(t) => {
						setTopic(t)
					}}/>
				</Box>
			</Grid>
			<Grid xs={8} sx={{pl: 4}}>
				{topic && renderTopicDetail(topic)}
			</Grid>
		</Grid>
		{
			question &&
			<QuestionEditor
				question={question}
				onSave={handleSaveQuestion}
				onCancel={handleCancelEditQuestion}
			/>
		}

	</>
}

export default ProductQuestions
