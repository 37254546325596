import React, { useState, useCallback, useRef, useEffect } from 'react'
import {
	TextField,
	Button,
	Container,
	Box,
	Typography,
	useTheme,
	InputAdornment,
	IconButton,
	Alert,
	AlertTitle,
	Divider
} from '@mui/material'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { getSupabase } from "../../util/supabase-utils"
import { ArrowBack, Check, Visibility, VisibilityOff } from "@mui/icons-material"
import { useUserStore } from "../../state"

const supabase = getSupabase()

const SignUp = ({ switchView }) => {
	const theme = useTheme()
	const { user } = useUserStore()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [showPassword, setShowPassword] = useState(false)
	const [captchaToken, setCaptchaToken] = useState('')
	const [error, setError] = useState('')
	const [success, setSuccess] = useState(false)
	const [loading, setLoading] = useState(false)
	const [resendLoading, setResendLoading] = useState(false)
	const [resendSuccess, setResendSuccess] = useState(false)
	const [lastResendTime, setLastResendTime] = useState(null)
	const resendCooldown = 30 // seconds
	const captchaRef = useRef(null)
	const captchaSiteKey = process.env.REACT_APP_HCAPTCHA_SITE_KEY

	const resetCaptcha = useCallback(() => {
		if (captchaRef.current) {
			captchaRef.current.resetCaptcha()
		}
		setCaptchaToken('')
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			if (captchaRef.current) {
				captchaRef.current.execute()
			}
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	const handleSignUpOrSignIn = useCallback(async () => {
		if (!email || !password) {
			setError('Please fill all fields.')
			return
		}
		if (!captchaToken) {
			setError('Please complete the captcha to verify you are not a bot.')
			return
		}

		try {
			setLoading(true)
			setError('')

			const { data: signInData, error: signInError } = await supabase.auth.signInWithPassword({
				email,
				password,
				options: { captchaToken }
			})

			if (signInData.user) {
				setSuccess(true)
				window.location.reload()
				return
			}

			const { data: signUpData, error: signUpError } = await supabase.auth.signUp({
				email,
				password,
				options: { captchaToken }
			})

			if (signUpError) throw signUpError

			setSuccess(signUpData.user && !signUpData.session)
			if (signUpData.session) window.location.reload()
		} catch (error) {
			console.error('Sign up/Sign in error:', error.message)
			setError("An error occurred. Please try again later.")
		} finally {
			setLoading(false)
			resetCaptcha()
		}
	}, [email, password, captchaToken, resetCaptcha])

	const handleResendVerification = useCallback(async () => {
		const now = Date.now()
		if (lastResendTime && now - lastResendTime < resendCooldown * 1000) {
			const remainingTime = Math.ceil((resendCooldown * 1000 - (now - lastResendTime)) / 1000)
			setError(`Please wait ${remainingTime} second${remainingTime !== 1 ? 's' : ''} before resending.`)
			return
		}

		if (!captchaToken) {
			setError('Please complete the captcha to verify you are not a bot.')
			return
		}

		try {
			setResendLoading(true)
			setError('')
			const { error } = await supabase.auth.resend({
				email,
				type: "signup",
				options: { captchaToken }
			})
			if (error) throw error
			setResendSuccess(true)
			setLastResendTime(now)
		} catch (error) {
			setError("Failed to resend verification email. Please try again.")
		} finally {
			setResendLoading(false)
			resetCaptcha()
		}
	}, [email, captchaToken, lastResendTime, resetCaptcha])

	return (
		<Container>
			{error && <Typography my={1} color="error" variant="body2">{error}</Typography>}
			{resendSuccess && <Typography my={1} color="success.main" variant="body2">Confirmation email resent successfully!</Typography>}
			{success ? (
				<Box display="flex" flexDirection="column">
					<Alert color="success">
						<AlertTitle>Success</AlertTitle>
						Account created successfully! Please check your email <b>{email}</b> to confirm your account.
						<Divider sx={{my: 2}}/>
						<Box display="flex" justifyContent="flex-start" alignItems="center">
							<Typography variant="body2">Didn't get the email? Check your spam folder or click Resend.</Typography>
							<Button
								sx={{ml: 1}}
								variant="outlined"
								size="small"
								onClick={handleResendVerification}
								disabled={resendLoading}
							>
								{resendLoading ? "Resending..." : "Resend"}
							</Button>
						</Box>
					</Alert>
				</Box>
			) : (
				<Box component="form" mt={3}>
					<Typography variant="h6" sx={{cursor: 'pointer'}} onClick={() => switchView('')} display="flex" alignItems="center">
						<ArrowBack sx={{mr: 0.5}}/>
						Register
					</Typography>
					<TextField
						label="Email"
						fullWidth
						margin="normal"
						value={email}
						disabled={loading}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<TextField
						label="Password"
						type={showPassword ? 'text' : 'password'}
						fullWidth
						margin="normal"
						value={password}
						disabled={loading}
						onChange={(e) => setPassword(e.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => setShowPassword(!showPassword)}
										onMouseDown={(event) => event.preventDefault()}
										edge="end"
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<Button
						variant="outlined"
						size="large"
						color="primary"
						disabled={loading}
						fullWidth
						sx={{ py: 2, mt: 2 }}
						onClick={handleSignUpOrSignIn}
					>
						{loading ? "Creating Account" : "Continue"}
					</Button>
				</Box>
			)}
			<Box mt={3} mb={2}>
				<Box flexDirection="column" display="flex" alignItems="center" justifyContent="center">
					<HCaptcha
						ref={captchaRef}
						size="invisible"
						sitekey={captchaSiteKey}
						onVerify={setCaptchaToken}
						theme={theme.palette.mode}
						onError={setError}
					/>
				</Box>
				{captchaToken && (
					<Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
						<Check color="success" fontSize="small" />
						<Typography variant="body2" ml={1}>
							Verified Human
						</Typography>
					</Box>
				)}
			</Box>
		</Container>
	)
}

export default SignUp