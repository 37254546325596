import React, {useState, useRef, useMemo} from 'react'
import {
	TextField,
	Button,
	Link,
	Container,
	Box,
	Typography,
	Divider,
	useTheme,
	InputAdornment,
	IconButton
}                                                        from '@mui/material'
import {Email, EmailOutlined, Visibility, VisibilityOff} from '@mui/icons-material'
import HCaptcha                                          from '@hcaptcha/react-hcaptcha'
import { getSupabase }                                   from "../../util/supabase-utils"
import Google                                            from "../icons/Google"
import LeftRight                                         from "../LeftRight"
import {useLocation}                                     from "react-router-dom"

const supabase = getSupabase()

const AuthIntro = ({ switchView }) => {
	const theme = useTheme()

	const handleGoogleLogin = async () => {
		const { error } = await supabase.auth.signInWithOAuth(
			{
				provider: 'google',
				options: {
					redirectTo: window.location.href
				}
			}
		)
		if (error) console.error('Google login error:', error.message)
	}

	return (
		<Container>
			<Box component="form" my={2}>
				<Typography variant="body" lineHeight={1.1} sx={{fontSize: '1.1em'}}>
					Register to access your complimentary study session.
				</Typography>

				<Box display="flex" flexDirection="column">
					<Button variant={"outlined"}
					        size="large"
					        fullWidth
					        sx={{ mt: 2, py: 2  }}
					        onClick={handleGoogleLogin}
					>
						<Google />
						<Box sx={{ ml: 1 }}>Continue with Google</Box>
					</Button>
				</Box>

				<Button variant="outlined"
				        size="large"
				        fullWidth
				        sx={{ mt: 2, py: 2 }}
				        onClick={()=>switchView('signUp')}
				>
					<EmailOutlined color={"info"} sx={{opacity: 0.5}} />
					<Box sx={{ ml: 1 }}>Create an Account</Box>
				</Button>


				<Box
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
					pt={2}
					pb={1}
				>
					<Box sx={{ width: '100%' }}>
						<Divider />
					</Box>
					<Box sx={{ width: '140%', fontSize: '.8em', color: theme.palette.text.disabled, textAlign: 'center' }}>
						Already registered?
					</Box>
					<Box sx={{ width: '100%' }}>
						<Divider />
					</Box>
				</Box>

				<Button size="large"
				        fullWidth
				        onClick={()=>switchView('login')}
				>
					Login to Continue
				</Button>

			</Box>
		</Container>
	)
}

export default AuthIntro
