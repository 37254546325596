import {Box, Button, IconButton, Tooltip, Typography, useTheme} from "@mui/material"
import {abcd}                                                   from "../../util/prepkit-utils"
import {ExpandLess, ExpandMore, Info, InfoOutlined}             from "@mui/icons-material"
import React, {useState}                                        from "react"
import LeftRight                                                from "../LeftRight"
import QuestionTranslator                                       from "../QuestionTranslator"

const MiniQuestion = ({question, topic, answerToShow, loading, collapsible, onSend, onAnswer}) => {

	const theme = useTheme()
	const [expanded, setExpanded] = useState(!collapsible)

	const style = {
		questionContainer: {
			backgroundColor: theme.palette.background.default,
			px: 4,
			pt: 3,
			pb: 2,
			borderRadius: 3,
			border: collapsible ? `5px solid ${theme.palette.background.paper}` : `none`,
			borderLeftWidth: 0,
			borderRightWidth: 0,
		},
		question: {
			fontSize: "28px",
			lineHeight: '32px',
		},
		topic: {
			fontWeight: 300,
			textTransform: "uppercase",
			padding: '10px 0 10px 0',
			color: theme.palette.secondary.main
		},
		options: {
			padding: "20px 0 10px 0",
		},
		option: {
			padding: "7px 0 7px 0"
		},
		optionLetter: {
			display: "inline-block",
			width: "34px",
			height: "34px",
			minWidth: "34px",
			minHeight: "34px",
			textAlign: "center",
			borderRadius: "25px",
			fontSize: "18px",
			border: "1px solid" + theme.palette.primary.main,
			padding: "3px 4px 4px 4px",
			marginTop: "3px",
			'&:hover': {
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
			},
			transition: 'background 250ms, borderColor 250ms, color 250ms'
		},
		optionLetterCorrect: {
			borderColor: theme.palette.success.main,
			backgroundColor: theme.palette.success.main,
			color: theme.palette.success.contrastText
		},
		optionLetterIncorrect: {
			borderColor: theme.palette.error.main,
			backgroundColor: theme.palette.error.main,
			color: theme.palette.success.contrastText
		},
		optionLabel: {
			flex: 1,
			display: "inline-block",
			fontSize: "18px",
			marginLeft: "15px",
			paddingTop: "7px",
			paddingBottom: "5px",
			transition: 'color 250ms'
		},
		optionLabelCorrect: {
			color: theme.palette.success.main
		},
		optionLabelIncorrect: {
			color: theme.palette.error.main,
		},
	}

	if (!question) {
		return null
	}


	return (
		<Box
			sx={[
				style.questionContainer,
				{
					opacity: loading ? .2 : 1,
					transition: 'opacity 250ms',
				}
			]}
		>
			<Box pb={2}>
				<LeftRight
					left={
						<Typography color={"secondary"}>{topic.name}</Typography>
					}
					right={
						<Typography sx={{opacity: 0.4}}>{question.number}&nbsp;of&nbsp;{topic.questions.length}</Typography>
					}
				/>
			</Box>

			<Box
				style={style.question}
				display="flex"
				justifyContent="space-between"
				alignItems="flex-end"
				sx={{
					cursor: collapsible ? "pointer" : "default"
				}}
				onClick={() => collapsible && setExpanded(!expanded)}
			>
				{question.question}
				{
					collapsible &&
					<Button size="small">
						{
							expanded
								? "Hide"
								: "Answers"
						}
						{
							expanded
								? <ExpandLess/>
								: <ExpandMore/>
						}
					</Button>
				}
			</Box>
			<Box style={style.options}>
				{question.options.map((o, i) => {
						const answered = answerToShow && answerToShow.answer === i
						const correct = answered && answerToShow.is_correct

						if (!expanded && !answered) {
							return null
						}

						return <Box
							key={`option-${i}`}
							style={style.option}
						>
							<Box
								sx={{
									width: '100%',
									padding: '5px 8px',
									cursor: "pointer",
									borderRadius: '25px',
									border: '0px solid transparent',
									'&:hover': {
										backgroundColor: theme.palette.background.paper,
										border: '0px solid ' + theme.palette.primary.light,
									},
								}}
								onClick={() => {
									if (!answerToShow || answerToShow.answer !== i) {
										onAnswer(question, i, o.label)
									}
								}}
								display="flex"
								flexDirection="row"
								justifyItems="space-between"
								alignItems="flex-start"
							>
								<Box sx={[
									style.optionLetter,
									answered
										? correct
											? style.optionLetterCorrect
											: style.optionLetterIncorrect
										: {}
								]}
								>
									{abcd(i)}
								</Box>
								<Box
									sx={[
										style.optionLabel,
										answered
											? correct
												? style.optionLabelCorrect
												: style.optionLabelIncorrect
											: {}
									]}
								>
									{o.label}
								</Box>
								<Tooltip placement="right" title={`Tell me about "${o.label}"`}>
									<IconButton
										onClick={(event) => {
											event.stopPropagation()
											event.preventDefault()
											onSend(`Tell me more about "${o.label}"`)
										}}
									>
										<InfoOutlined fontSize="14px"/>
									</IconButton>
								</Tooltip>
							</Box>
						</Box>
					}
				)}
			</Box>

			<QuestionTranslator onTranslate={(languageName) => {
				onSend(`Show this question in ${languageName}`)
			}}/>

		</Box>)
}

export default MiniQuestion