import React from 'react';
import MiniNavbar from '../mini/MiniNavbar'; // Create a specific Navbar for MiniQuestionView
import { Outlet } from 'react-router-dom';

const MiniLayout = ({ toggleTheme }) => {
	return (
		<div>
			<MiniNavbar toggleTheme={toggleTheme} />
			<div style={{marginTop: "72px"}}>
				<Outlet /> {/* This will render the matched child route */}
			</div>
		</div>
	);
};

export default MiniLayout;
