import {useNavigate}                          from "react-router-dom"
import '../stylesheets/Navbar.css'
import React, {useState}                      from "react"
import {
	AppBar,
	Box,
	Button,
	CssBaseline,
	Divider,
	Drawer,
	IconButton,
	LinearProgress,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Toolbar,
	useTheme
}                                                             from "@mui/material"
import AccountCircle                                          from "@mui/icons-material/AccountCircle"
import ThemeToggle                                            from "./ThemeToggle"
import {BookmarkBorder, ExpandMore, Logout, Menu as MenuIcon} from "@mui/icons-material"
import useMediaQuery                                          from "@mui/material/useMediaQuery"
import {useUserStore}                                         from "../state"
import BirdsyLogo                                             from "./BirdsyLogo"
import BirdsyMiniLogo                                         from "./BirdsyMiniLogo"
import useUserRoles                                           from "../hooks/useUserRoles"
import LeftRight                                              from "./LeftRight"



const Navbar = React.forwardRef((
	{currentTheme, toggleTheme }, ref
) => {

	const isAdmin = useUserRoles(['admin'])
	const {user, signOut} = useUserStore()
	const theme = useTheme()
	const navigate = useNavigate()

	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
	const [accountMenu, setAccountMenu] = useState(false)
	const [navMenuAnchorEl, setNavMenuAnchorEl] = useState(null)
	const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null)
	const [navLinks] = useState([
		{
			name: "Shop",
			to: "/shop",
		},
		{
			name: "Prep",
			to: "/prepkit",
		}
	])

	const logout = () => {
		setAccountMenu(false)
		signOut()
	}

	const handleNavMenuClick = (event) => {
		setNavMenuAnchorEl(event.currentTarget)
	}
	const handleNavMenuClose = () => {
		setNavMenuAnchorEl(null)
	}

	const handleAccountClick = (event) => {
		setAccountMenuAnchorEl(event.currentTarget)
	}
	const handleAccountMenuClose = () => {
		setAccountMenuAnchorEl(null)
	}

	const adminMenu = isAdmin && <Box>
		<Divider/>
		<MenuItem onClick={() => navigate("/admin")}>
			Admin
		</MenuItem>
		<MenuItem onClick={() => navigate("/admin/users")}>
			Users
		</MenuItem>
		<MenuItem onClick={() => navigate("/admin/licenses")}>
			Licenses
		</MenuItem>
		<MenuItem onClick={() => navigate("/admin/products")}>
			Products
		</MenuItem>
		<MenuItem onClick={() => navigate("/admin/packages")}>
			Packages
		</MenuItem>
		<Divider/>
		<MenuItem onClick={() => navigate("/admin/videos")}>
			Videos
		</MenuItem>
		<MenuItem onClick={() => navigate("/admin/topic-job-queue")}>
			Content Job Queue
		</MenuItem>
		<MenuItem onClick={() => navigate("/admin/app-config")}>
			App Config
		</MenuItem>
		<MenuItem onClick={() => navigate("/admin/bad-json")}>
			Bad JSON
		</MenuItem>

		<Divider/>

		<MenuItem onClick={() => navigate("/admin/palette")}>
			Browse Palette
		</MenuItem>

		<MenuItem onClick={() => navigate("/admin/check-access")}>
			Check Access
		</MenuItem>
	</Box>

	return (
		<>
			<AppBar ref={ref} position={"fixed"} elevation={0} color="transparent"
			        sx={{background: 'transparent'}}>
				<CssBaseline/>
				<Toolbar>
					<div style={{cursor: "pointer", paddingTop: 5}} onClick={() => navigate('/')}>
						<BirdsyMiniLogo width={130}/>
					</div>
					<div className={"nav-menu-container"}>
						<div className={"nav-menu-left"}>

						</div>
					</div>

					{user &&
					<div className={"account-menu-container"}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="account-menu"
							aria-haspopup="true"
							onClick={handleAccountClick}
						>
							<AccountCircle/>
						</IconButton>

						<Menu
							anchorEl={accountMenuAnchorEl}
							id="account-menu"
							open={(accountMenuAnchorEl !== null)}
							onClose={handleAccountMenuClose}
							onClick={handleAccountMenuClose}
							transformOrigin={{horizontal: 'right', vertical: 'top'}}
							anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
						>

							<MenuItem>
								<LeftRight
									left={<span>Dark Mode</span>}
									right={<ThemeToggle toggleTheme={toggleTheme}/>}
								/>
							</MenuItem>

							<Divider/>
							<ListItemText
								secondary={user.email}
								sx={{px: 2}}
							/>
							{user &&
							<MenuItem onClick={() => navigate(`/account/profile`)}>
								<ListItemIcon>
									<AccountCircle fontSize="small"/>
								</ListItemIcon>
								<ListItemText primary="Profile"/>

							</MenuItem>
							}
							{user &&
							<MenuItem onClick={logout}>
								<ListItemIcon>
									<Logout fontSize="small"/>
								</ListItemIcon>
								<ListItemText primary="Sign Out"/>
							</MenuItem>
							}
						</Menu>
					</div>
					}

					{user && adminMenu &&
					<div className={"account-menu-container"}>
						<IconButton
							size="large"
							onClick={handleNavMenuClick}
							aria-controls="nav-menu"
							aria-haspopup="true"
						>
							<MenuIcon />
						</IconButton>

						<Menu
							anchorEl={navMenuAnchorEl}
							id="nav-menu"
							open={(navMenuAnchorEl !== null)}
							onClose={handleNavMenuClose}
							onClick={handleNavMenuClose}
							transformOrigin={{horizontal: 'right', vertical: 'top'}}
							anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
						>
							{navLinks.map((link, ix) => (
								<MenuItem key={link.name}
								          onClick={() => navigate(link.to)}
								          color="primary"
								>
									{link.name}
								</MenuItem>
							))}

							{adminMenu}

						</Menu>
					</div>
					}
				</Toolbar>
			</AppBar>
			<Toolbar className="navbar-background"/> {/* Empty Toolbar to push content down */}
		</>
	)
})

export default Navbar