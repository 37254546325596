import React, {useEffect, useState}                                                             from 'react'
import {
	Alert,
	Box, Button,
	Container,
	Dialog, DialogActions, DialogContent,
	InputAdornment,
	List,
	ListItemButton,
	TextField,
	Typography,
	useTheme,
} from '@mui/material'
import Grid                     from '@mui/material/Unstable_Grid2' // Grid version 2
import {useNavigate, useParams} from "react-router-dom"
import {doGet, doPost}          from "../../util/do-fetch"
import {logger}                 from "../../util/log-utils"
import {Close, Search}               from "@mui/icons-material"
import PackageCard                   from "./PackageCard"
import PurchasePackage               from "./PurchasePackage"
import MiniSkeleton                  from "./MiniSkeleton"
import BirdsyMiniLogo                from "../BirdsyMiniLogo"
import AuthIntro                     from "../auth/AuthIntro"
import Login                         from "../auth/Login"
import ForgotPassword                from "../auth/ForgotPassword"
import SignUp                        from "../auth/SignUp"
import UpdatePassword                from "../auth/UpdatePassword"
import LeftRightCenter               from "../LeftRightCenter"
import {findFirstTopicWithQuestions} from "../../util/prepkit-utils"

const log = logger("MiniShop", 1)

function MiniShop() {

	const navigate = useNavigate()
	const theme = useTheme()
	const { id, optionId } = useParams()
	const [packages, setPackages] = useState(null)
	const [prepKits, setPrepKits] = useState(null)
	const [trialAvailable, setTrialAvailable] = useState(false)
	const [packageToTry, setPackageToTry] = useState(null)
	const [selectedPackage, setSelectedPackage] = useState(null)
	const [selectedOption, setSelectedOption] = useState(null)
	const [filteredPackages, setFilteredPackages] = useState([])
	const [filter, setFilter] = useState("")
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		fetchPackages()
	}, [])

	useEffect(() => {
		if(packages) {
			const pkg = packages.find(p=>p.id===id)
			const opt = pkg?.options.find(o=>o.id === optionId)
			setSelectedPackage(pkg || null)
			setSelectedOption(opt || null)
		}
	}, [id, optionId, packages])

	useEffect(() => {
		filterPackages()
	}, [packages, filter])

	const fetchPackages = async () => {
		try {
			const response = await doGet(`/mini/shop`)
			const data = await response.json()
			setPackages(data.packages)
			setPrepKits(data.prepKits)
			setTrialAvailable(data.trialAvailable)
		}
		catch (err) {
			log.error(err)
		}
	}

	const filterPackages = () => {
		setFilteredPackages(packages && packages.filter(p => p.name.toUpperCase()
			.indexOf(filter.toUpperCase()) !== -1))
	}

	const handleClick = (pkg) => {
		if(trialAvailable) {
			setPackageToTry(pkg)
		}
		else {
			navigate(`/shop/${pkg.id}`)
		}
	}
	const handleTryPackage = async () => {
		try {
			const response = await doPost(`/mini/package/${packageToTry.id}/try`)

			if (response.ok) {
				const data = await response.json()
				const prepKit = data.prepKit
				if(prepKit) {
					navigate(`/prepkit/${prepKit.id}`)
				}
				else {
					throw `Your complimentary study session has expired.`
				}
			}
			else if (response.status === 403) {
				// forbidden
				throw `Your complimentary study session has expired.`
			}
		}
		catch (err) {
			log.error(err)
			setError(err)
		}
	}

	const handleSelectPackage = (selectedPackage) => {
		console.log(`select package ${selectedPackage?.id}`)
		if(selectedPackage) {
			navigate(`/shop/${selectedPackage.id}`)
		}
	}

	const handleSelectOption = (selectedPackage, selectedOption) => {
		console.log(`select option ${selectedPackage?.id}, ${selectedOption?.id}`);

		let url = '/shop';

		if (selectedPackage?.id) {
			url += `/${selectedPackage.id}`;

			if (selectedOption?.id) {
				url += `/option/${selectedOption.id}`;
			}
		}

		navigate(url);
	};


	return (
		<Container maxWidth="lg">
			<Grid xs={12} spacing={3} justifyContent="center" py={3}>
				<Typography variant="h2" style={{textAlign: 'center'}}>Choose your PrepKit</Typography>
			</Grid>
			<Grid xs={12} container spacing={3} justifyContent="center" py={3}>
				<TextField
					autoComplete="off"
					fullWidth={true}
					name="package-filter"
					variant="standard"
					style={{
						display: "block",
						borderRadius: "30px",
						width: "100%",
						maxWidth: "800px",
						padding: "15px",
						border: "1px solid " + theme.palette.divider
					}}
					placeholder="Find your PrepKit..."
					value={filter}
					onChange={(event) => {
						setFilter(event.target.value)
					}}
					InputProps={{
						disableUnderline: true,
						endAdornment: <InputAdornment position="end">
							<Search/>
						</InputAdornment>,
					}}
				/>
			</Grid>
			{
				error &&
				<Alert severity={"error"}>
					{error}
				</Alert>
			}
			<Grid xs={12} container spacing={3}>
				{filteredPackages && filteredPackages.map((pkg) => (
					<Grid item="true" xs={8} sm={4} md={3}
					      key={`package-${pkg.id}`}
					>
						<PackageCard packageData={pkg}
						             actionLabel={trialAvailable ? "Try" : "Select"}
						             onClick={handleClick}
						/>
					</Grid>
				))}
			</Grid>
			{
				prepKits &&
				<Grid xs={12} container spacing={3} pt={2}>
					<Grid item="true" xs={12} sm={6} md={4}>
						<Box p={2} border={`1px solid ${theme.palette.divider}`} borderRadius={1}>
							<Typography variant="h5">Your PrepKits</Typography>
							<List>
								{prepKits && prepKits.map((pk) => (
									<ListItemButton key={`prepKit-${pk.id}`} onClick={()=>navigate(`/prepkit/${pk.id}`)}>
										{pk.name}
									</ListItemButton>
								))}
							</List>
						</Box>
					</Grid>
				</Grid>
			}
			{
				selectedPackage &&
					<PurchasePackage
						packages={[selectedPackage]}
						selectedPackage={selectedPackage}
						selectedOption={selectedOption}
						onSelectPackage={handleSelectPackage}
						onSelectOption={handleSelectOption}
						onCancel={() => {
							navigate('/shop')
						}}
					/>
			}
			{
				packageToTry &&
					<Dialog
						open={packageToTry}
					>
						<DialogContent sx={{width: 500}}>
							<LeftRightCenter

								left={
									<Box p={2}>
										<Close sx={{color: "transparent"}}/>
									</Box>
								}
								center={
									<Box display="flex" justifyContent="center" pb={2}>
										<BirdsyMiniLogo width={125}/>
									</Box>
								}
								right={
									<Box>
										<Button size={"small"} sx={{mt: 1}} onClick={()=>setPackageToTry(null)}>
											<Close color={"error"}/>
										</Button>
									</Box>
								}
							/>
							<Box display={"flex"}
							     justifyContent={"center"}
							     flexDirection={"column"}
							     pt={2}
							     px={2}
							>
								<Typography variant={"h6"}>Try PrepKit</Typography>
								<Typography>
									You're about to start your complimentary study session
									with the
									<b>
										&nbsp;{packageToTry.name}&nbsp;
									</b>
									PrepKit.  Ready?
								</Typography>
							</Box>
						</DialogContent>
						<DialogActions>
							<Box display={"flex"}
							     justifyContent={"center"}
							     alignItems={"center"}
							     flexDirection={"column"}
							     width={"100%"}
							     pb={2}
							>
								<Button sx={{width: '200px', py: 1, mt: 2}}
								        disabled={loading}
								        variant={"outlined"}
								        color={"success"}
								        onClick={handleTryPackage}
								>
									Let's Go &rarr;
								</Button>
							</Box>
						</DialogActions>
					</Dialog>
			}
		</Container>
	)
}

export default MiniShop
