import React, {useEffect, useState}                                 from "react"
import {useNavigate, useParams}                                     from "react-router-dom"
import {Container, useTheme, InputBase, IconButton, Fab, Box, Link} from "@mui/material"
import Grid                                                         from "@mui/material/Unstable_Grid2"
import SearchIcon                                                   from '@mui/icons-material/Search'
import { logger }                                                   from "../../util/log-utils"
import "../../stylesheets/MiniQuestionChat.css"
import {ArrowForward, ChevronRight, Close, Send, SendOutlined}      from "@mui/icons-material"
import {doGet}                                                      from "../../util/do-fetch"
import PurchasePackage                                              from "./PurchasePackage"
import {useUserStore}                                               from "../../state"

const log = logger("MiniCodeView", 1)

function MiniCodeView({ toggleTheme }) {
	log.trace("Component render")

	const { user } = useUserStore()
	const theme = useTheme()
	const navigate = useNavigate()
	const { code: defaultCode } = useParams()
	const [packages, setPackages] = useState(null)
	const [prepKit, setPrepKit] = useState(null)
	const [code, setCode] = useState(defaultCode || '')
	const [error, setError] = useState(null)

	useEffect(()=> {
		if(defaultCode) {
			handleButtonClick()
		}
	}, [defaultCode])

	const handleInputChange = (event) => {
		const value = event.target.value.toUpperCase() // Convert to uppercase
		const alphanumericValue = value.replace(/[^A-Z0-9]/gi, '') // Remove non-alphanumeric characters
		if (alphanumericValue.length <= 5) { // Limit to 5 characters
			setCode(alphanumericValue)
		}
	}

	const handleButtonClick = async () => {
		if (code && code.length === 5) {
			// not signed in, but next screen with force auth so navigate away!
			navigate(`/q/${code}`)
		} else {
			setError("Question code invalid.")
		}
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleButtonClick()
		}
	}

	if(packages && packages.length) {
		return <PurchasePackage packages={packages} prepKit={prepKit} title={"Buy stuff"}/>
	}

	return (
		<Container style={{ maxWidth: '800px', marginTop: '20%', textAlign: 'center' }}>
			<Grid container justifyContent="center">
				<h2 style={{marginRight: 1, fontWeight: "normal", color: theme.palette.text.disabled}}>
					Enter Question Code
				</h2>
				<Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
					<Box width={80} height={80}></Box>
					<InputBase
						value={code}
						onChange={handleInputChange}
						onKeyDown={handleKeyDown}
						autoFocus={true}
						placeholder="_____"
						sx={{
							fontSize: '32px',
							fontFamily: 'monospace',
							borderRadius: '50px',
							backgroundColor: theme.palette.background.paper,
							border: `1px solid ` + (error ? theme.palette.error.light : theme.palette.divider),
							padding: '10px 30px',
							width: '100%',
							maxWidth: '500px',
							letterSpacing: '20px',
							xboxShadow: '0px 3px 25px rgba(0,0,0,0.15)',
							display: 'flex',
							textAlign: 'center',
							justifyContent: 'center',
							alignItems: 'center',
							mx: 2
						}}
						inputProps={{ maxLength: 5, sx: {textAlign: 'center'} }}
					/>
					<Box width={80} height={80}>
						<Fab
							onClick={handleButtonClick}
							sx={{
								marginTop: '12px',
								fontSize: '32px',
								boxShadow: 'none',
							}}
						>
							<ArrowForward style={{ fontSize: '32px' }} />
						</Fab>
					</Box>
				</Box>
				<Box>
					<Box textAlign="center" color={theme.palette.error.light} minHeight={25}>
						{error}
					</Box>
					<Box mt={9} color={theme.palette.text.secondary}>
						No question code?  <Link href="/explore">Explore Birdsy &rarr;</Link>
					</Box>
				</Box>

			</Grid>
		</Container>
	)
}

export default React.memo(MiniCodeView)
