import React, {createContext, useState, useEffect, useContext, useMemo} from 'react'
import {useParams, Outlet, useNavigate, useLocation}                    from 'react-router-dom'
import {doGet}                                                          from "../../util/do-fetch"
import moment                                                                     from "moment"
import {getSupabase}                                                              from "../../util/supabase-utils"
import {useUserStore}                                                             from "../../state"
import {logger}                                                                                     from "../../util/log-utils"
import {
	Box,
	Button,
	Chip,
	CircularProgress,
	Container,
	Divider,
	Drawer,
	Fab,
	Link,
	Typography,
	useTheme
} from "@mui/material"
import CircularProgressWithLabel                                                                    from "../CircularProgressWithLabel"
import TopicToc                                                                   from "./TopicToc"
import {Close, ExpandMore}                                                        from "@mui/icons-material"
import ExpiringPrepKit                                                            from "./ExpiringPrepKit"
import Grid                                                                       from "@mui/material/Unstable_Grid2"
import {isExpired}                                                                from "../../util/prepkit-utils"

const log = logger("MiniPrepKits", 1)

const MiniPrepKits = () => {
	const theme = useTheme()
	const navigate = useNavigate()
	const location = useLocation()
	const {user} = useUserStore()
	const hash = location.hash
	const [prepKits, setPrepKits] = useState(null)

	const isBack = useMemo(()=>{
		return hash === '#back'
	}, [hash])

	useEffect(() => {
		fetchPrepKits()
	}, [])

	const fetchPrepKits = async () => {
		const response = await doGet(`/mini/prepkit`)
		const data = await response.json()

		if(data && data.length === 1) {
			// if there's only one, then navigate to it
			const pk = data[0]

			//... unless its expired
			if(!isExpired(pk) && !isBack) {
				navigateToPrepKit(pk)
			}
			else {
				setPrepKits(data)
			}
		}
		else {
			setPrepKits(data)
		}
	}

	const navigateToPrepKit = (prepKit) => {
		navigate(prepKit.id)
	}

	return (
		<Container>
			<Grid>
				<h2>PrepKits</h2>
				<Box>
				{
					prepKits && prepKits.map(pk => {
						return <PrepKitButton key={pk.id} prepKit={pk} onClick={()=>navigateToPrepKit(pk)} />
					})
				}
				</Box>

				<Box mt={4}>
					<Button sx={{cursor: "pointer"}}
					      onClick={()=>navigate('/shop')}
					>
						Discover more PrepKits &rarr;
					</Button>
				</Box>
			</Grid>
		</Container>
	)
}

const PrepKitButton = ({prepKit, onClick}) => {

	const expiresAt = useMemo(()=>{
		return prepKit && moment(prepKit.expires_at).calendar()
	}, [prepKit])

	return <Button
		key={prepKit.id}
		sx={{
			mr: 1,
			mb: 1,
			textAlign: "left"
		}}
		variant={"outlined"}
		size="large"
		onClick={()=>onClick(prepKit)}
	>
		<Box display={"flex"}
		     flexDirection={"column"}
		     justifyContent={"flex-start"}
		     alignItems={"flex-start"}
		>
			<Typography>{prepKit.name}</Typography>
			<Typography variant={"body2"}>
				Licensed Until {expiresAt}
			</Typography>

		</Box>
	</Button>
}

export default MiniPrepKits
