import React, {useState}                                         from 'react'
import {Box, Button, InputAdornment, Stack, TextField, useTheme}                          from '@mui/material'
import {
	Add, AddCircle,
	ArrowCircleUp,
	ArrowCircleUpOutlined,
	ArrowUpward, ArrowUpwardOutlined,
	AutoAwesome,
	Close, CloseOutlined,
	KeyboardArrowDown,
	Send
} from '@mui/icons-material'
import {logger}                                                                           from "../../../util/log-utils"

const log = logger("SendMessage", 1)

const SendMessage = ({onSend, actions, placeholder, onAction}) => {
	const theme = useTheme()
	const [body, setBody] = useState("")
	const [showActions, setShowActions] = useState(false)
	const [hasFocus, setHasFocus] = useState(false)
	const [clickedHideActions, setClickedHideActions] = useState(false)

	const handleSend = () => {
		setShowActions(false)
		const s = body
		setBody("")
		onSend(s)
	}

	const handlePlus = () => {
		setShowActions(prev => {
			if(prev) setClickedHideActions(true)
			return !prev
		})
	}

	const handleAction = (action) => {
		onAction && onAction(action)
		setShowActions(false)
	}

	const actionsStyle = {
		transition: 'height 200ms',
		height: showActions ? (actions.length * 55) + 'px' : 0,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		backgroundColor: theme.palette.translucent,
		backdropFilter: "blur(10px)",
		WebkitBackdropFilter: "blur(10px)",
		margin: '0 30px'
	}

	const startAdornment = actions && actions.length > 0 &&
		<InputAdornment
			style={{cursor: "pointer"}}
			onClick={handlePlus}
			position="start">
			{ showActions ? <Close sx={{color: theme.palette.error.main}} /> : <Add/> }
		</InputAdornment>


	return (
		<Box display="flex" flex={1} flexDirection="column">
			<div style={actionsStyle}>
				{
					actions && actions.length && actions.map((a,i) => {
						return <Box key={'action-' + i} sx={{padding: '10px 0 0 10px'}}>
							<Button
								onClick={() => handleAction(a)}
						        style={{textTransform: 'none'}}
								size="large"
							>
								{a.label}
							</Button>
						</Box>
					})
				}
			</div>
			<Box display="flex" justifyContent={"center"}>
				<TextField
					autoComplete="off"
					autoFocus={true}
					name="send-message"
					fullWidth={true}
					variant="outlined"
					sx={{
						'& .MuiOutlinedInput-root': {
							borderRadius: '30px',
							backgroundColor: theme.palette.translucent,
							border: '1px solid ' + theme.palette.divider,
							backdropFilter: "blur(10px)",
							WebkitBackdropFilter: "blur(10px)",
							'& fieldset': {
								border: '1px solid ' + theme.palette.divider, // Default border color
							},
							'&:hover fieldset': {
								border: '1px solid ' + theme.palette.divider, // Border color on hover
							},
							'&.Mui-focused fieldset': {
								border: '1px solid ' + theme.palette.secondary.main, // Border color when focused
							},
						},
						marginX: "auto" }}
					placeholder={placeholder || "Ask me something"}
					value={body}
					onChange={(event) => {
						setBody(event.target.value)
					}}
					onKeyPress={(event) => {
						if (event.key === 'Enter') {
							handleSend()
						}
					}}
					onFocus={()=>setHasFocus(true)}
					onBlur={()=>setHasFocus(false)}
					InputProps={{
						startAdornment: startAdornment,
						endAdornment: <InputAdornment
							style={{cursor: "pointer"}}
							onClick={handleSend}
							position="end"
						>
							<ArrowUpward color={hasFocus ? "secondary" : ""} />
						</InputAdornment>,
					}}
				/>
			</Box>
		</Box>
	)
}

export default SendMessage
