import React, {useMemo, useState}                                            from 'react'
import {Backdrop, Box, Container, Dialog, DialogContent, Skeleton, useTheme} from "@mui/material"
import getSupabase                                                           from "../../util/supabase-utils"
import BirdsyMiniLogo                                                        from "../BirdsyMiniLogo"
import Login                                                                 from "./Login"
import SignUp                                                                from "./SignUp"
import ForgotPassword                                                        from "./ForgotPassword"
import styled                                                                from "@emotion/styled"
import UpdatePassword                                                        from "./UpdatePassword"
import {useUserStore}                                                        from "../../state"
import MiniSkeleton                                                          from "../mini/MiniSkeleton"
import AuthIntro                                                             from "./AuthIntro"
import {useLocation}                                                         from "react-router-dom"

function BirdsyAuth({view}) {

	const {user} = useUserStore()
	const theme = useTheme()
	const supabase = getSupabase()
	const authProviders = ['google', 'apple', 'microsoft'] //, 'facebook', 'twitter']
	const [currentView, setCurrentView] = useState(view || (!user && ''))
	const animation = false //"pulse"


	const switchView = (view) => {
		setCurrentView(view)
	}

	return (
		<Container style={{maxWidth: '800px', textAlign: 'center'}}>
			<MiniSkeleton/>
			<Dialog
				open={true}
			>
				<DialogContent sx={{width: 500}}>
					<Box display="flex" justifyContent="center" pb={2}>
						<BirdsyMiniLogo width={125}/>
					</Box>
					{currentView === '' && <AuthIntro switchView={switchView}/>}
					{currentView === 'login' && <Login switchView={switchView}/>}
					{currentView === 'forgotPassword' && <ForgotPassword switchView={switchView}/>}
					{currentView === 'signUp' && <SignUp switchView={switchView}/>}
					{currentView === 'update' && <UpdatePassword switchView={switchView}/>}

				</DialogContent>
			</Dialog>
		</Container>
	)

}

export default BirdsyAuth
