import '../stylesheets/App.css'
import React, {useEffect, useState} from "react"
import {useNavigate}                from "react-router-dom"
import {Box, Button}                from "@mui/material"
import {doPost}                     from "../util/do-fetch"
import {useUserStore}               from "../state"
import {logger}                     from "../util/log-utils"
import getSupabase                  from "../util/supabase-utils"
import Grid                         from "@mui/material/Unstable_Grid2"
import useUserRoles                 from "../hooks/useUserRoles"

const log = logger("Home", 1)

function Home(props) {
	const { user } = useUserStore()
	const isAdmin = useUserRoles(['admin'])
	const navigate = useNavigate()

	useEffect(() => {
		if (!user) {
			navigate("/login")
		}
	}, [user])

	return (
		<Grid container>
			<Grid xs={2} xsOffset={5}>
				<Box style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "flex-start",
				}}>
					<Button
						size="large"
						color="divider"
						sx={{m: 2, fontSize: '16px', p: 2, width: '300px'}}
						variant="contained"
						onClick={() => navigate("/shop")}
					>
						Shop
					</Button>
					<Button
						size="large"
						color="divider"
						sx={{m: 2, fontSize: '16px', p: 2, width: '300px'}}
						variant="contained"
						onClick={() => navigate("/prepkit")}
					>
						Prep
					</Button>
					{
						isAdmin && <Button
							size="large"
							color="divider"
							sx={{m: 2, fontSize: '16px', p: 2, width: '300px'}}
							variant="contained"
							onClick={() => navigate("/admin")}
						>
							Admin
						</Button>
					}

				</Box>
			</Grid>
		</Grid>
	)
}

export default Home