import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js'
import {useMemo, useState}                   from 'react'
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormGroup,
	Link,
	Typography,
	useTheme
}                                            from "@mui/material"
import {Alert}                               from "@mui/material"
import {Lock}                                from "@mui/icons-material"
import LeftRight                             from "../LeftRight"
import {fmt2Dec}                             from "../../util/prepkit-utils"
import moment                                from "moment"


const MiniCheckout = ({clientSecret, selectedPackage, selectedOption, onPurchaseComplete}) => {
	const theme = useTheme()
	const stripe = useStripe()
	const elements = useElements()
	const [error, setError] = useState(null)
	const [processing, setProcessing] = useState(false)
	const [termsAccepted, setTermsAccepted] = useState(false)

	const optionAmount = useMemo(() => {
		return `$${fmt2Dec(selectedOption.price_in_cents / 100)}`
	}, [selectedOption])

	const optionExpires = useMemo(() => {
		const today = moment()
		today.add(selectedOption.license_days, 'd')
		return today.format('MM-DD-YYYY h:mm a')
	}, [selectedOption])

	const cardElementOptions = {
		style: {
			base: {
				color: theme.palette.text.primary,
				fontFamily: 'Helvetica, Arial, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: theme.palette.text.secondary,
				},
			},
			invalid: {
				color: theme.palette.secondary.main,
				iconColor: theme.palette.secondary.main,
			},
		},
	}

	const handleSubmit = async (event) => {
		event.preventDefault()
		setProcessing(true)

		const {error, paymentIntent} = await stripe.confirmCardPayment(clientSecret, {
			payment_method: {
				card: elements.getElement(CardElement)
			}
		})

		if (error) {
			setError(error.message)
			setProcessing(false)
		}
		else {
			onPurchaseComplete(paymentIntent)
			setProcessing(false)
		}
	}

	return (
		<Box>
			<form onSubmit={handleSubmit}>
				<Box
					m={2}
					mt={1}
					border={1}
					borderColor={theme.palette.divider}
					borderRadius={1}
					p={2}
				>
					<LeftRight
						left={<Box>{selectedPackage.name}</Box>}
						right={<Typography variant={"h7"}>
							{optionAmount}
						</Typography>}
					/>
					<Box>{selectedOption.name}</Box>
					<Box><small><i>Licensed until {optionExpires}</i></small></Box>
				</Box>
				{
					clientSecret
					? <>
						<Box p={2}
						     mx={2}
						     mb={1}
						     sx={{
							     border: `1px solid ${theme.palette.divider}`,
							     borderRadius: 1
						     }}>
							<CardElement options={cardElementOptions}/>
						</Box>
						<FormControl sx={{p: 2}} component="fieldset" required>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={termsAccepted}
											onChange={(event) => {
												setTermsAccepted(event.target.checked)
											}}
											name="terms"
											color="primary"
										/>
									}
									label={
										<Typography variant="body2">
											I agree to the <Link href="https://birdsy.ai/terms-and-conditions" target="_blank"
											                     rel="noopener noreferrer">Terms and
											Conditions</Link> and <Link href="https://birdsy.ai/privacy-policy" target="_blank"
											                            rel="noopener noreferrer">Privacy Policy</Link>
										</Typography>
									}
								/>
							</FormGroup>
						</FormControl>
					  </>
					: <Box pb={5} textAlign={"center"}>
						<CircularProgress size={18}/> Securing payment connection
					  </Box>
				}
				{
					error &&
					<Alert color="error" onClose={() => setError(null)}>{error}</Alert>
				}
				<Box pb={3} textAlign="center">
					<Button
						type="submit"
						variant="outlined"
						size="large"
						color="success"
						disabled={!stripe || processing || !termsAccepted}
					>
						<Lock fontSize="small" sx={{mr: 1}}/>
						{processing ? 'Processing...' : 'Purchase'}
					</Button>
				</Box>
			</form>
		</Box>
)
}

export default MiniCheckout