import React, {useState}                                              from 'react'
import {Button, Card, CardActions, CardContent, Typography, useTheme} from '@mui/material'
import {PieChart}                                                     from "@mui/x-charts"
import Grid                                                 from "@mui/material/Unstable_Grid2"
import {useNavigate}                                        from "react-router-dom"


const PackageCard = ({packageData, actionLabel, onClick}) => {
	const theme = useTheme()
	const [elevation, setElevation] = useState(0); // Default elevation

	if(!packageData) return null

	return (
		<Card
			style={{
				width: '100%',
				cursor: 'pointer',
				transition: 'backgroundColor 500ms',
				backgroundColor: elevation === 0 ? theme.palette.background.default : theme.palette.background.paper
			}}
		    onClick={() => onClick && onClick(packageData)}
		    onMouseOver={() => setElevation(1)}
		    onMouseOut={() => setElevation(0)}
		    elevation={elevation}
		>
			<CardContent>
				<Typography sx={{ fontSize: 14 }} color={elevation === 0 ? 'default' : 'secondary'} gutterBottom>
					PREPKIT
				</Typography>
				<Typography variant="h5" component="div" style={{minHeight: '75px'}}>
					{packageData.name}
				</Typography>
				<Typography variant="body">

				</Typography>
			</CardContent>
			<CardActions>
				<Button /*Click rolls up to card parent handler */ >
					{actionLabel}
				</Button>
			</CardActions>
		</Card>
	)
}
export default PackageCard