import { createStore } from 'zustand/vanilla'
import { create } from 'zustand'
import { getSupabase } from '../util/supabase-utils'

// Create the store configuration
const configureStore = (set, get) => ({
	session: getSupabase().session,
	user: getSupabase().session?.user,
	setSession: (session) => set(() => ({ session, user: session?.user })),
	signOut: async () => {
		const { error } = await getSupabase().auth.signOut()
		if (error) {
			console.error("Error signing out:", error)
		}
		set({ session: null, user: null })
	}
})

// Create a vanilla store
const store = createStore(configureStore)

// Create a hook to use the store
export const useUserStore = create(store)

