import React, {useContext, useEffect, useMemo, useRef, useState}                                   from "react"
import {
	useLocation,
	useNavigate,
	useParams
}                                                                                                  from 'react-router-dom'
import {
	DEBUG,
	logger
}                                                                                                  from "../../util/log-utils"
import {
	doPut,
	doStreamPost
}                                                                                                  from "../../util/do-fetch"
import {Avatar, Box, Chip, Container, Fab, IconButton, Link, Slide, Tooltip, Typography, useTheme} from "@mui/material"
import "../../stylesheets/PrepKit.css"
import "../../stylesheets/PrepKitAIChat.css"
import "../../stylesheets/MiniQuestionChat.css"
import Grid
                                                                                                   from "@mui/material/Unstable_Grid2"
import {
	abcd,
	createQueuedUserMessage,
	createStreamingAiMessage,
}                                                                                                  from "../../util/prepkit-utils"
import SendMessage
                                                                                                   from "../prepkit/chat/SendMessage.js"
import Messages
                                                                                                   from "../prepkit/chat/Messages.js"
import {useUserStore}                                                                              from "../../state"
import {
	ArrowBack,
	ArrowBackOutlined,
	ArrowForward,
	ArrowForwardOutlined,
	KeyboardBackspace
}                                                                                                  from "@mui/icons-material"
import "../../stylesheets/MiniQuestionChat.css"
import {useInView}                                                                                 from "react-intersection-observer"
import MiniQuestion                                                                                from "./MiniQuestion"
import {MiniPrepKitContext}                                                                        from "./MiniPrepKit"
import {
	findQuestionInPrepKit,
	findTopicInPrepKit
}                                                                                                  from "../../util/mini"
import moment                                                                                      from "moment"
import getSupabase
                                                                                                   from "../../util/supabase-utils"
import LeftRight                                                                                   from "../LeftRight"
import Score                                                                                       from "./Score"
import StreamingMarkdown
                                                                                                   from "../prepkit/chat/StreamingMarkdown"
import {keyframes}                                                                                 from "@emotion/react"

const log = logger("MiniQuestionView", DEBUG)

const actions = [
	{
		type: "MEMORIZATION",
		label: "Help me memorize this",
		prompt: "Provide a tool to help me memorize this."
	},
	{
		type: "EXAMPLE",
		label: "Real life example",
		prompt: "Give a real life example."
	},
	{
		type: "INSIGHT",
		label: "Tell me more",
		prompt: "Provide more details."
	},
	{
		type: "HINT",
		label: "Hint, please",
		prompt: "Give me a hint."
	}
]

const fetchMessages = async (prepKit, question) => {
	log.debug("fetchMessages")
	const {data: msgs, error} = await getSupabase()
		.from('message')
		.select('*')
		.eq('prepkit_id', prepKit.id)
		.eq('question_id', question.id)
		.order('created_at', {ascending: true})

	if (error) {
		log.error('Error fetching messages:', error)
		return null
	}
	return msgs
}

const scrollToBottom = (element, animate = true) => {
	element?.scrollIntoView({behavior: animate ? 'smooth' : 'auto'})
}

function MiniQuestionView() {
	log.trace("Component render")

	const theme = useTheme()
	const navigate = useNavigate()
	const location = useLocation()
	const {user} = useUserStore()
	const {code} = useParams()
	const prepKit = useContext(MiniPrepKitContext)
	const hash = location.hash

	const [loadedAt, setLoadedAt] = useState()
	const [messages, setMessages] = useState([])
	const [loadedMessagesQuestionId, setLoadedMessagesQuestionId] = useState(null)
	const [isAutoScroll, setIsAutoScroll] = useState(true)
	const [showAnswerHistory, setShowAnswerHistory] = useState(false)
	const [showMessageHistory, setShowMessageHistory] = useState(false)
	const [currentAnswer, setCurrentAnswer] = useState(null)

	const [isPinned, setIsPinned] = useState(false)
	const [showTopic, setShowTopic] = useState(false)
	const [showTopicScore, setShowTopicScore] = useState(false)
	const [topicScoreMessage, setTopicScoreMessage] = useState(null)

	const messagesEndRef = useRef(null)
	const lastScrollTopRef = useRef(0)
	const prevTopicIdRef = useRef()
	const prevQuestionRef = useRef()
	const prevAnswerRef = useRef()

	const {ref: pinRef, entry} = useInView({
		threshold: 0.5, // Trigger when 50% of the element is visible
		triggerOnce: false, // Keep triggering as visibility changes
	})

	const isBack = useMemo(() => {
		return hash === '#back'
	}, [hash])

	const {topic, question} = useMemo(() => {
		if (prepKit && code) {
			return findQuestionInPrepKit(prepKit, code)
		}
		return {product: null, topic: null, question: null}
	}, [prepKit, code])

	const parentTopics = useMemo(() => {
		if (topic) {
			return topic.path.split('/')
				.filter(p => p)
				.slice(0, -1)
		}
		else {
			return null
		}
	}, [topic])

	const filteredMessages = useMemo(() => {
		if (showMessageHistory) {
			return messages
		}
		else {
			return messages && messages.filter(m => moment(m.created_at)
				.isSameOrAfter(loadedAt || 0))
		}

	}, [loadedAt, messages, showMessageHistory])

	const olderMessages = useMemo(() => {
		return messages && messages.filter(m => moment(m.created_at)
			.isBefore(loadedAt))
	}, [loadedAt, messages])

	const answerToShow = useMemo(() => {
		return currentAnswer ||
			(showAnswerHistory && question && question.userAnswer)
	}, [currentAnswer, showAnswerHistory, question])

	// window scroll listener
	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	useEffect(() => {
		const storedValue = localStorage.getItem('showAnswerHistory')
		if (storedValue !== null) {
			setShowAnswerHistory(JSON.parse(storedValue))
		}
	}, [])

	// auto scroll to bottom
	useEffect(() => {
		if (isAutoScroll) {
			scrollToBottom()
		}
	}, [messages, isAutoScroll])

	useEffect(() => {
		const prevQuestion = prevQuestionRef.current
		const questionChanged =
			(prevQuestion == null && question != null) ||
			(prevQuestion != null && question == null) ||
			(prevQuestion != null &&
				question != null &&
				prevQuestion.id !== question.id)

		if (questionChanged) {
			setCurrentAnswer(null)
			setLoadedAt(moment())
			setIsPinned(false)
		}

		// Update the ref with the current question
		prevQuestionRef.current = question

	}, [question])

	useEffect(() => {
		if (!topic) return

		if (prevTopicIdRef.current && prevTopicIdRef.current !== topic.id) {

			const handleReviewTopic = (topicToReview) => {
				const aiMsg = createStreamingAiMessage(true, false)
				aiMsg.stream = true

				setTopicScoreMessage(aiMsg)

				const onData = (data) => {
					setTopicScoreMessage(prev => {
						const clone = {...prev}
						clone.streaming = true
						clone.body += data
						console.log(clone.body)
						return clone
					})
				}

				const onComplete = (data) => {
					setTopicScoreMessage(prev => {
						const clone = {...prev}
						clone.streaming = false
						clone.body = data
						return clone
					})
				}

				const url = `/mini/prepkit/${prepKit.id}/review-topic`
				const options = {
					body: JSON.stringify({topic: topicToReview})
				}
				doStreamPost(url, options, onData, onComplete)
					.catch(err => {
						log.debug("Error sending message")
						log.debug(err)
						throw err
					})
			}

			const prevTopic = findTopicInPrepKit(prepKit, prevTopicIdRef.current)
			const lastQuestionInPrevTopic = prevTopic.questions[prevTopic.questions.length - 1]
			const prevAnsweredQuestion = prevAnswerRef && prevAnswerRef.current && prevAnswerRef.current.question

			// Check if the user just answered the last question of the previous topic
			const justAnsweredLastQuestion = lastQuestionInPrevTopic &&
				prevAnsweredQuestion &&
				prevAnsweredQuestion.id === lastQuestionInPrevTopic.id

			// Check if all questions in the previous topic are answered
			const allQuestionsAnswered = prevTopic.percentAnswered === 1
			/*
			console.log({
				allQuestionsAnswered,
				justAnsweredLastQuestion,
				lastQuestionInPrevTopic: lastQuestionInPrevTopic?.id,
				prevAnsweredQuestion: prevAnsweredQuestion?.id,
				percentAnswered: prevTopic.percentAnswered,
				prevTopic
			})
			 */

			prevAnswerRef.current = null
			if (justAnsweredLastQuestion && allQuestionsAnswered) {
				setShowTopicScore(prevTopic)
				handleReviewTopic(prevTopic)
			}
			else {
				setShowTopicScore(null)
			}

			setShowTopic(true)

		}
		prevTopicIdRef.current = topic.id
	}, [topic, prepKit])

	useEffect(() => {
		if (currentAnswer) {
			prevAnswerRef.current = currentAnswer
		}
	}, [currentAnswer])

	useEffect(() => {
		const fetchAndSetMessages = async () => {
			if (!prepKit || !question || (question.id === loadedMessagesQuestionId)) return

			setLoadedMessagesQuestionId(question.id)
			const fetchedMessages = await fetchMessages(prepKit, question)

			setMessages(prevMessages => {
				if (prevMessages.length > 0 && prevMessages[0].questio_id === question.id) {
					return [...prevMessages, ...fetchedMessages]
						.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
				}
				return fetchedMessages || []
			})

			setTimeout(() => scrollToBottom(messagesEndRef && messagesEndRef.current), 300)
		}

		log.debug(`useEffect[question:${question?.id}]`)

		if (question && showMessageHistory) {
			fetchAndSetMessages()
		}
	}, [prepKit, question, showMessageHistory, loadedMessagesQuestionId, messagesEndRef])


	const nextQuestion = async () => {
		navigate(`/prepkit/${prepKit.id}/q/${question.next}#next`)
	}

	const previousQuestion = async () => {
		navigate(`/prepkit/${prepKit.id}/q/${question.previous}#back`)
	}

	const saveAnswer = async (answer) => {
		const url = `/mini/prepkit/${prepKit.id}/question/${question.id}/answer/${answer}`
		await doPut(url)
	}

	const handleScroll = () => {
		const scrollTop = window.scrollY
		const scrollHeight = document.documentElement.scrollHeight
		const clientHeight = window.innerHeight

		// Determine scroll direction
		const scrollDirection = scrollTop > lastScrollTopRef.current ? 'down' : 'up'
		lastScrollTopRef.current = scrollTop

		// If the user scrolls up, disable auto-scroll
		if (scrollDirection === 'up') {
			setIsAutoScroll(false)
		}

		// Re-enable auto-scroll if the user is at the bottom of the page
		if (scrollHeight - scrollTop === clientHeight) {
			setIsAutoScroll(true)
		}
	}

	const handleSend = async (message, answerClicked) => {
		setShowTopic(null)
		const msgs = [...messages]

		if (!message || message.trim().length === 0) {
			return
		}

		let userMsg = createQueuedUserMessage(message, true, false)
		msgs.push(userMsg)

		const aiMsg = createStreamingAiMessage(true, false)
		aiMsg.streaming = true

		// add aiMsg to local state but NOT to array sent to server
		log.debug("setMessages:: adding aiMsg")
		setMessages([...msgs, aiMsg])

		setTimeout(() => {
			setIsAutoScroll(true)
			scrollToBottom()
		}, 100)

		const onData = (data) => {
			log.debug("setMessages:: streaming onData")
			setMessages(prev => {
				const index = prev.findIndex(m => aiMsg.id === m.id)
				if (index !== -1) {
					// Found message to update with streaming data
					const updatedMessages = [...prev]
					const msg = updatedMessages[index]//cloneDeep(updatedMessages[index])
					msg.streaming = true
					msg.body += data // Update body
					return updatedMessages
				}
				else {
					return prev  // No matching message found, so no change
				}
			})
		}

		const onComplete = (data) => {
			// do we need to do anything?
			// todo: you would think we should set aiMsg.stream = false and update messages state, but at
			// this point the server is about to insert the message into the database we will receive
			// the INSERT event on message, so let's just let that happen which will cause it to update
			// messages state anyway
			// ... so, no, don't do anything
			log.debug("streaming onComplete, calling setMessages " + data)

			log.debug("setMessages:: streaming onComplete")
			setMessages(prev => {
				const index = prev.findIndex(m => aiMsg.id === m.id)
				if (index !== -1) {
					log.debug("setMessages:: Found message to update with streaming data")
					const updatedMessages = [...prev]
					const msg = updatedMessages[index]
					msg.body = data
					msg.streaming = false
					return updatedMessages // Replace the old messages array
				}
				else {
					log.debug("setMessages:: No matching message found, so no change")
					return prev  // No matching message found, so no change
				}
			})
		}

		const sendUrl = `/mini/prepkit/${prepKit.id}/question/${question.id}/send?saveAnswer=${answerClicked !== true}`
		doStreamPost(sendUrl, {body: JSON.stringify(msgs)}, onData, onComplete)
			.catch(err => {
				log.debug("Error sending message")
				log.debug(err)
				throw err
			})
	}

	const handleAnswer = async (q, a, label) => {
		setCurrentAnswer({
			question: q,
			answer: a,
			is_correct: question.answer === a,
			created_at: new Date()
		})
		handleSend(`${abcd(a)}. ${label}`, true)
		await saveAnswer(a)
	}

	// Update the question pinned state based on the middle point of the element
	if (entry) {
		let triggerY = entry.boundingClientRect.top + entry.boundingClientRect.height / 2
		if (triggerY <= 0 && !isPinned) {
			setIsPinned(true)
		}
		else if (triggerY > 0 && isPinned) {
			setIsPinned(false)
		}
	}

	if (!question) return null

	const fabSx = {
		border: `1px solid ${theme.palette.divider}`,
		backgroundColor: theme.palette.background.default,
		'&:hover': {
			border: `1.5px solid ${theme.palette.secondary.main}`
		},
		'&:hover .MuiSvgIcon-root': {
			color: theme.palette.secondary.main,
		},
		'.MuiSvgIcon-root': {
			color: theme.palette.text.primary
		},
		boxShadow: 'none !important',
	}

	const pulseAnimation = keyframes`
	    0% {
	      background-color: ${theme.palette.secondary.dark};
	    }
	    50% {
	      background-color: ${theme.palette.secondary.light};
	    }
	    100% {
	      background-color: ${theme.palette.secondary.dark};
	    }
	  `

	const fabSxPulse = {
		animation: `${pulseAnimation} 1s infinite`, // 2s duration, infinite loop
		'.MuiSvgIcon-root': {
			color: 'white'
		},
	}

	return (
		<div style={{border: '0px solid purple'}}>
			<Slide direction={showTopic
				? isBack
					? 'left'
					: 'right'
				: isBack
					? 'right'
					: 'left'
			} in={!showTopic} timeout={theme.transitions.duration.standard}>
				<Container style={{padding: 0, maxWidth: '800px', border: "0px solid orange"}}>
					<Box display={"flex"}
					     flexDirection={"row"}
					     alignItems={"center"}
					     onClick={() => navigate(`/prepkit/${prepKit && prepKit.id}`)}
					     sx={{cursor: 'pointer', ml: -0.75}}
					>
						<IconButton>
							<KeyboardBackspace/>
						</IconButton>

						{
							parentTopics &&
							parentTopics.length > 0 &&
							<Typography variant={"body"}>
								{parentTopics.join(' / ')}
							</Typography>
						}

					</Box>

					{/*[{loadedAt && moment(loadedAt).format('hh:mm:ss a')}]*/}
					<Grid sm={12} ref={pinRef} sx={{border: '0px solid magenta'}}>
						{/*[{loadedAt && moment(loadedAt).format('hh:mm:ss a')}]*/}
						<Grid sm={12} ref={pinRef} sx={{border: '0px solid magenta'}}>
							<MiniQuestion answerToShow={answerToShow}
							              question={question}
							              topic={topic}
							              onSend={handleSend}
							              onAnswer={handleAnswer}
							/>
							{
								<LeftRight
									left={

										question &&
										question.userAnswer &&
										!currentAnswer &&
										<Box sx={{
											fontSize: "13px",
											p: 1,
											cursor: 'pointer'
										}}
										     onClick={() => setShowAnswerHistory(!showAnswerHistory)}
										>
											{
												showAnswerHistory
													? <Link sx={{cursor: 'pointer', textDecoration: 'none'}}
													        onClick={() => setShowAnswerHistory(!showAnswerHistory)}
													>
														Hide Previous Answer
													</Link>
													: <Tooltip
														title={`Click to show previous answer`}
														enterDelay={500}
													>
														<span>
															Show Previous Answer
															{
																question.userAnswer.is_correct
																	? <span style={{
																		cursor: 'pointer',
																		color: theme.palette.success.main,
																		textDecoration: 'underline',
																		marginLeft: '3px'
																	}}>
																Correct
															  </span>
																	: <span style={{
																		color: theme.palette.error.main,
																		textDecoration: 'underline',
																		marginLeft: '3px'
																	}}>
																Incorrect
															  </span>
															}
														</span>
													</Tooltip>

											}
										</Box>
									}
									right={
										(
											question.hasMessages ||
											(olderMessages && olderMessages.length > 0)
										) &&
										<Box sx={{fontSize: "13px", p: 1}}>
											<Link sx={{cursor: 'pointer', textDecoration: 'none'}}
											      onClick={() => setShowMessageHistory(!showMessageHistory)}
											>
												{showMessageHistory ? 'Hide ' : 'Show '} Older Messages
											</Link>
										</Box>
									}
								/>

							}
						</Grid>
						{
							isPinned &&
							<Box
								sx={{
									position: 'fixed',
									top: 0,
									left: 0,
									right: 0,
									zIndex: 1000,
									border: '0px solid magenta',
									opacity: showTopic ? 0 : 1
								}}
							>
								<Box
									sx={{
										width: '950px',
										margin: '0 auto',
										px: 9,
										transition: 'background 250ms',
										backgroundColor: theme.palette.transparent,
										xxbackdropFilter: "blur(10px)",
										xxWebkitBackdropFilter: "blur(10px)",
										border: '0px solid magenta'
									}}
								>
									<MiniQuestion answerToShow={answerToShow}
									              question={question}
									              topic={topic}
									              collapsible={true}
									              onSend={handleSend}
									              onAnswer={handleAnswer}
									/>
								</Box>
							</Box>
						}
					</Grid>
					<Grid sm={12} sx={{
						mt: 1,
						pb: '200px',
						border: '0px solid red',
					}}>
						{/*
						<pre>{JSON.stringify({hasMessages: question.hasMessages})}</pre>
						<pre>{JSON.stringify({messages: messages && messages.length})}</pre>
						<pre>{JSON.stringify({currentAnswer})}</pre>
						<pre>{JSON.stringify({prevAnswerRef: prevAnswerRef.current})}</pre>
						<pre>{JSON.stringify({topic: topic.percentAnswered})}</pre>
						<pre>{JSON.stringify({showAnswerHistory})}</pre>
						<pre>{JSON.stringify({showMessageHistory})}</pre>
						<pre>{JSON.stringify({olderMessages: olderMessages.length})}</pre>
						<pre>{JSON.stringify({loadedAt})}</pre>
						*/}

						{
							!showTopic &&
							<Messages
								contextId={user.id}
								messages={filteredMessages}
								showMessagesAfter={0}
							/>
						}
						{
							filteredMessages &&
							filteredMessages.length === 0 &&
							!currentAnswer &&
							<Box display="flex" pt={10} justifyContent="center">
								{
									actions.map(a => {
										return <Chip
											key={a.label}
											label={a.label}
											color="info"
											variant="outlined"
											onClick={() => handleSend(a.prompt || a.label)}
											deleteIcon={<ArrowForward/>}
											sx={{
												cursor: "pointer",
												marginLeft: '5px',
												marginRight: '5px',
												padding: '0',
												fontSize: '.9em',
												'&.MuiChip-root': {
													xpadding: '26px',
													xborderRadius: '26px'
												}
											}}
										/>
									})
								}
							</Box>
						}
						{/*
							currentAnswer &&
							<Box display="flex" justifyContent="center">
								<Chip
									label="Next"
									color="info"
									variant="outlined"
									onClick={nextQuestion}
									onDelete={nextQuestion}
									deleteIcon={<ArrowForward/>}
									sx={{
										cursor: "pointer",
										marginLeft: '5px',
										marginRight: '5px',
										padding: '0',
										fontSize: '.9em',
										'&.MuiChip-root': {
											xpadding: '26px',
											xborderRadius: '26px'
										}
									}}
								/>
							</Box>
						*/}
					</Grid>
				</Container>
			</Slide>
			{/*<Fade in={!showTopic} timeout={theme.transitions.duration.standard}>*/}
			<Box sx={{
				position: "fixed",
				bottom: 0,
				left: 0,
				right: 0,
				border: "0px solid red",
				zIndex: 1200
			}}>
				<Box
					sx={{
						py: 2,
						width: "800px",
						marginX: "auto",
					}}
					display="flex"
					justifyContent="center"
					alignItems="flex-end"
				>
					<Box sx={{mr: 1}}
					     display="flex"
					     flex={1}
					>
						<SendMessage
							onSend={handleSend}
							actions={actions}
							messageInputDisabled={showTopic}
							placeholder="Have a question? Enter it here."
							onAction={(a) => handleSend(a.prompt || a.label)}
						/>
					</Box>
					<Fab sx={[fabSx, {mx: 1}]}
					     onClick={() => {
						     if (showTopicScore) {
							     setShowTopicScore(false)
						     }
						     else {
							     if (showTopic) {
								     setShowTopic(false)
								     if (!isBack) previousQuestion()
							     }
							     else {
								     previousQuestion()
							     }
						     }
					     }}
					>
						<ArrowBackOutlined/>
					</Fab>
					<Fab sx={[fabSx, currentAnswer ? fabSxPulse : null]}
					     onClick={() => {
						     if (showTopicScore) {
							     setShowTopicScore(false)
						     }
						     else {
							     if (showTopic) {
								     setShowTopic(false)
								     if (isBack) nextQuestion()
							     }
							     else {
								     nextQuestion()
							     }
						     }
					     }}
					>
						<ArrowForwardOutlined sx={{color: currentAnswer ? 'white' : 'inherit'}}/>
					</Fab>
				</Box>
			</Box>
			{/*</Fade>*/}
			<div ref={messagesEndRef}/>

			<Slide direction={
				showTopic
					? isBack
						? 'right'
						: 'left'
					: isBack
						? 'left'
						: 'right'
			}
			       in={showTopic}
			       timeout={theme.transitions.duration.standard}
			>
				<Box
					sx={{
						position: 'absolute',
						top: "70px",
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: theme.palette.background.paper,
						border: `0px solid magenta`,
						zIndex: 1100,
						pb: "70px",
						display: 'flex',              // Add this
						flexDirection: 'column',      // Add this
						justifyContent: 'flex-start',     // Add this if you want vertical centering
					}}
				>

					{
						showTopicScore &&
						<Box
							display={"flex"}
							flexDirection={"column"}
							justifyContent={"flex-start"}
							alignItems={"center"}
							maxWidth={"800px"}
							alignSelf={"center"}
							border={"0px solid red"}
						>
							{
								showTopicScore &&
								<LeftRight
									left={
										<Typography variant="h4"
										            sx={{
											            py: 2,
											            display: "block",
											            border: "px solid red",
										            }}
										>
											{showTopicScore.name} Complete!
										</Typography>
									}
									right={
										<Box pl={2}>
											<Score variant={"contained"}
											       topic={showTopicScore}
											       size={35}
											       complete={1}/>
										</Box>
									}
								/>
							}
							{
								showTopicScore &&
								topicScoreMessage &&
								<Box
									border={"0px solid orange"}
									width={"100%"}
									display={"flex"}
									flex={1}
									flexDirection={"row"}
									alignSelf={"center"}
									justifyContent={"flex-start"}
									alignItems={"flex-start"}
									borderTop={`1px solid ${theme.palette.divider}`}
								>
									<Avatar src="/birdsy-avatar-black.png"
									        sx={{
										        border: '1px solid ' + theme.palette.divider,
										        padding: '2px',
										        width: 30,
										        height: 30,
										        mr: 2,
										        ml: 0,
										        mt: 2,
										        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)'
									        }}/>
									<StreamingMarkdown text={topicScoreMessage.body}
									                   streaming={topicScoreMessage.streaming}/>
								</Box>
							}
						</Box>
					}
					{
						!showTopicScore &&
						<Box display={"flex"}
						     flex={1}
						     justifyContent={"center"}
						     alignItems={"center"}
						     flexGrow={1}
						     border={"0px solid orange"}
						     height={"100%"}
						     margin={"0 auto"}
						     paddingBottom={"70px"}
						     flexDirection={"column"}
						>
							<Box
								display={"flex"}
								flex={1}
								justifyContent={"center"}
								alignItems={"center"}
								flexDirection={"row"}
								sx={{
									border: "0px solid red",
								}}
							>
								{
									isBack &&
									<Typography variant="h2" sx={{mt: 6, mr: 2}}>
										<ArrowBack sx={{fontSize: 'inherit'}}/>
									</Typography>
								}
								<Box>
									<Typography variant={"h5"} sx={{opacity: 0.5, ml: 0.5}}>
										{parentTopics?.join(' / ')}
									</Typography>
									<Typography variant="h2">
										{topic.name}
									</Typography>
								</Box>
								{
									!isBack &&
									<Typography variant="h2" sx={{mt: 6, ml: 2}}>
										<ArrowForward sx={{fontSize: 'inherit'}}/>
									</Typography>
								}
							</Box>
						</Box>
					}
				</Box>
			</Slide>
		</div>
	)
}

export default React.memo(MiniQuestionView)